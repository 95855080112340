import React from 'react';
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const PrivateRoutes = () => {
    const { location } = useLocation();
    const authenticated = useAuth();

    return (
        authenticated ? <Outlet /> : <Navigate to="login" state={{ from: location }} replace />
    );
};

export default PrivateRoutes;
