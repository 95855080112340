import React from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardBody, Col, UncontrolledPopover, PopoverBody, PopoverHeader } from 'reactstrap';
import {
  BarChart, Bar, Cell, ResponsiveContainer, Tooltip,
} from 'recharts';
import TrendingDownRoundedIcon from '@material-ui/icons/TrendingDownRounded';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import numeral from "numeral";
import { useSelector } from "react-redux";


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  dataContainer: {
  	display: "inline-block"
  },
  dateContainer:{
  	fontSize: "9px"
  }
}));

const DynamicPopover = ({ helpInfo }) => (
	<UncontrolledPopover trigger="focus" placement="top" target="help">
            <PopoverHeader>{helpInfo}</PopoverHeader>
            <PopoverBody>
            	{helpInfo}
            </PopoverBody>
    </UncontrolledPopover>
);


const SimpleDataDisplay = props => {
	const classes = useStyles();
	const state = useSelector(state => state);
	const [dataSet, setDataSet] = React.useState(props.data);
	const [title, setTitle] = React.useState(props.title);
	const [dir, setDir] = React.useState(props.dir);
	const [help, setHelp] = React.useState(props.help);
	const [id, setId] = React.useState(props.id);
	const [activeIndex, setActiveIndex] = React.useState(0);
	const activeItem = dataSet[activeIndex];
	const [popoverOpen, setPopOverOpen] = React.useState(false);
	const [financialStatementsHelpDesc, setFinancialStatementsHelpDesc] = React.useState({});

	React.useEffect(() => {
		setDataSet(props.data);
		setDir(props.dir);
		setTitle(props.title);
		setHelp(props.help);
		setId(props.id);
		setActiveIndex(props.activeChartIndex);
	}, [props]);

	React.useEffect(() => {
		if (state && state.financialStatementsHelpDesc && state.financialStatementsHelpDesc.data) {
			let helpDesc = [];
			for (let [key, value] of Object.entries(state.financialStatementsHelpDesc.data)) {
				const val = value;
				for (let [key, value] of Object.entries(val)) {
					helpDesc[`${key}`] = value;
				}
			}
			setFinancialStatementsHelpDesc(helpDesc);
		}
	}, [state.financialStatementsHelpDesc]);

	const handleClick = (item) => {
	    const index = dataSet.indexOf(item.payload);
	    setActiveIndex(index);
	    props.onItemChartSelection(index);
  	};

  	const showHelp = () => {
  		ReactDOM.render(DynamicPopover(help), document.getElementById('simpleDataContainer'));
  	};

  	const toggle = () => {
    	setPopOverOpen(!popoverOpen);
  	};

  	const showValue = () => {
  		if(activeItem){
	  		let value = activeItem?parseFloat(activeItem.value):0;
	  		let key = activeItem.name;
	  		let finalValue = value;
	  		let signValue = Math.sign(value);
	  		let absValue = Math.abs(value);
	  		if(key !== 'eps' && key !== 'eps_diluted'){
				absValue = absValue/1000000;
				let sign = "";
				if(signValue > 0 || signValue == 0)
					sign = "";
				else if(signValue < 0 || signValue == -0)
					sign = "-";
				if(absValue > 0)
					finalValue = sign + numeral(absValue).format('0,0');
			}
	        return finalValue;
    	}
  	}

  	function CustomTooltip({ payload, label, active }) {
	  if (active) {
	    return (
	  <div className="custom-tooltip">
	        <p className="label">{`${payload[0].payload.date}`}</p>
	      </div>
	    );
	  }
	  return null;
	}

	return (
	  <Col key={id} id={id} className={classes.dataContainer} md={12} xl={3} lg={6} xs={12}>
	    <Card>
	      <CardBody className="dashboard__card-widget panel__body">
	      	<div className="panel__btns_updt">
	      		<IconButton className="panel__btn_updt" id={"popover_"+id} aria-label="more info">
	      			<InfoOutlinedIcon/>
	      		</IconButton>
	      	</div>
	        <div key={title} className="panel__title">
	          <h5 className="bold-text">{title}</h5>
	        </div>
	        <div className="dashboard__total">
				{activeItem && activeItem.value > 0 ?
	          		<TrendingUpRoundedIcon className="dashboard__trend-icon" />
					:
					<TrendingDownRoundedIcon className="dashboard__down_trend-icon" />
				}

	          <p className="dashboard__total-stat">
	            { showValue() }
	          </p>
	        </div>
	      	<div className="dashboard__chart-container_updt">
	            <ResponsiveContainer height={50}>
	              <BarChart data={dataSet}>
	              	<Tooltip content={<CustomTooltip />}/>
	                <Bar dataKey="value" onClick={handleClick}>
	                  {
	                    dataSet.map((entry, index) => {
								return entry.value > 0 ?
									(<Cell
										key={entry.date}
										cursor="pointer"
										fill={index === activeIndex ? '#4ce1b6' : '#D0CAC6'}
										key={`cell-${index}`}
									/>)
									:
									(<Cell
										key={entry.date}
										cursor="pointer"
										fill={index === activeIndex ? '#ff4861' : '#D0CAC6'}
										key={`cell-${index}`}
									/>);
							}
	                    )
	                  }
	                </Bar>
	              </BarChart>
	            </ResponsiveContainer>
	            <div className={classes.dateContainer}>
	            	{activeItem && activeItem.date}
	            </div>
	        </div>
	      </CardBody>
	    </Card>
	    <UncontrolledPopover trigger="legacy" placement="top" target={"popover_"+id}>
            <PopoverBody>
            	{
            		financialStatementsHelpDesc[title]
            	}
            </PopoverBody>
    	</UncontrolledPopover>
	  </Col>
	);
};

export default SimpleDataDisplay;