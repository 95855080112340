import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3, 2),
	},
	gridRoot: {
		flexGrow: 1,
	},
	bodyContainer: {
		paddingLeft: "10px",
		paddingRight: "10px"
	},
	customTypographyH6: {
		fontWeight: "bold"
	}
}));


const CompanyProfile = props => {
	const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const [companyName, setCompanyName] = React.useState('');
    const [sector, setSector] = React.useState('');
    const [industry, setIndustry] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [ceo, setCeo] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [city, setCity] = React.useState('');
    const [addressState, setAddressState] = React.useState('');
    const [zipcode, setZipCode] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [website, setWebsite] = React.useState('');


	React.useEffect(() => {
		if(state.companyProfile.data){
			setCompanyName(state.companyProfile.data.companyName);
			setSector(state.companyProfile.data.sector);
			setIndustry(state.companyProfile.data.industry);
			setDescription(state.companyProfile.data.description);
			setCeo(state.companyProfile.data.ceo);
			setAddress(state.companyProfile.data.address);
			setCity(state.companyProfile.data.city);
			setAddressState(state.companyProfile.data.state)
			setZipCode(state.companyProfile.data.zip);
			setWebsite(state.companyProfile.data.website);
		}
	},[state.companyProfile.data]);

	return (
			<>
				<Typography gutterBottom variant="h6" classes={{h6:classes.customTypographyH6}}>
					Profile
				</Typography>
            	<Grid container spacing={3}>
			        <Grid item xs>
			          	<Typography class="cp-name" variant="h6" gutterBottom>
							{companyName}
						</Typography>
			        </Grid>
			        <Grid item xs>
			          	<Typography class="cp-sector" variant="subtitle1" gutterBottom>
							Sector: {sector}
						</Typography>
			        </Grid>
			        <Grid item xs>
			          	<Typography class="cp-industry" variant="subtitle1" gutterBottom>
							Industry: {industry}
						</Typography>
			        </Grid>
		      	</Grid>
		      	<Grid container spacing={3}>
			        <Grid item xs>
			        	<Typography class="cp-description" variant="body1" gutterBottom>
							{description}
						</Typography>
			        </Grid>
			    </Grid>
			    <Grid container spacing={3}>
			        <Grid item xs>
			        	<Typography class="cp-ceo" variant="body1" gutterBottom>
							<div>{ceo}</div>
							<div>Chief Executive Officer</div>	
						</Typography>
			        </Grid>
			        <Grid item xs>
			        	<Typography class="cp-address" variant="body1" gutterBottom>
							<div>{address}</div>
							<div>{city}, {addressState}</div>
							<div>{zipcode}</div>
							<div>{country}</div>
						</Typography>
			        </Grid>
			      	<Grid item xs>
			        	<Typography class="cp-website" variant="body1" gutterBottom>
							<a href={website} target="_blank">{website}</a>
						</Typography>
			        </Grid>
			    </Grid>
			</>
	);
};

export default CompanyProfile;