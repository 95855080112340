import React, { useEffect, useState } from 'react';
import {useSelector} from "react-redux";

import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";



const useStyles = makeStyles(theme => ({
    tableContainer: {
        display: "table"
    },
    tableRow: {
        display: "table-row"
    },
    tableColumnName: {
        display: "table-cell",
        width: "200px"
    },
    tableColumnValue: {
        display: "table-cell"
    },
    dateDisplay: {
        fontWeight: "bolder"
    },
    customTypographyH6: {
        fontWeight: "bold"
    }
}));


const TabularData = ({ data }) => {
  const classes = useStyles();

  return (
      <div className={classes.tableRow}>
          <div className={classes.tableColumnName}>
              <Typography variant="button" display="block" gutterBottom>
                  { data.key.toLowerCase() === "Date".toLowerCase() && (
                      "Date"
                  ) }
                  { data.key.toLowerCase() === "analystRatingsbuy".toLowerCase() && (
                      <Button variant="outlined" color="primary">Buy</Button>
                  ) }
                  { data.key.toLowerCase() === "analystRatingsHold".toLowerCase() && (
                      <Button variant="outlined" color="default">Hold</Button>
                  ) }
                  { data.key.toLowerCase() === "analystRatingsSell".toLowerCase() && (
                      <Button variant="outlined" color="secondary">Sell</Button>
                  ) }
                  { data.key.toLowerCase() === "analystRatingsStrongSell".toLowerCase() && (
                      <Button variant="outlined" color="secondary">Strong Sell</Button>
                  ) }
                  { data.key.toLowerCase() === "analystRatingsStrongBuy".toLowerCase() && (
                      <Button variant="outlined" color="primary">Strong Buy</Button>
                  ) }
              </Typography>
          </div>
          <div className={classes.tableColumnValue}>
              <Typography variant="overline" display="block" gutterBottom>
                  { data.key.toLowerCase() === "Date".toLowerCase() && (
                      <span className={classes.dateDisplay}>{data.value}</span>
                  ) }
                  { data.key.toLowerCase() === "analystRatingsbuy".toLowerCase() && (
                      <Button variant="outlined" color="primary">{data.value}</Button>
                  ) }
                  { data.key.toLowerCase() === "analystRatingsHold".toLowerCase() && (
                      <Button variant="outlined" color="default">{data.value}</Button>
                  ) }
                  { data.key.toLowerCase() === "analystRatingsSell".toLowerCase() && (
                      <Button variant="outlined" color="secondary">{data.value}</Button>
                  ) }
                  { data.key.toLowerCase() === "analystRatingsStrongSell".toLowerCase() && (
                      <Button variant="outlined" color="secondary">{data.value}</Button>
                  ) }
                  { data.key.toLowerCase() === "analystRatingsStrongBuy".toLowerCase() && (
                      <Button variant="outlined" color="primary">{data.value}</Button>
                  ) }
              </Typography>
          </div>
      </div>
  );
};

const AnalystView = (props) => {
    const classes = useStyles();
    const state = useSelector(state => state);
    const [analystStockRecommendation, setAnalystStockRecommendation] = useState({});

    const actualNames = ["date", "analystRatingsbuy", "analystRatingsHold", "analystRatingsSell", "analystRatingsStrongSell","analystRatingsStrongBuy"];
    const customDisplayNames = ["Date", "Buy", "Hold", "Sell", "Strong Buy", "Strong Sell"];

    const getCustomDisplayName = (key) => {
        actualNames.forEach((name, index) => {
            if(key.toLowerCase() === name.toLowerCase()) {
                return customDisplayNames[index];
            }
        });
    };

    useEffect(() => {
        if(state.companyInfo && state.companyInfo.analyst_stock_recommendation) {
            setAnalystStockRecommendation(state.companyInfo.analyst_stock_recommendation[0]);
        }
    },[]);

    return (
      <>
          { analystStockRecommendation ? (
          <>
              <Typography gutterBottom variant="h6" classes={{h6:classes.customTypographyH6}}>
                  Analyst Stock Recommendation
              </Typography>
             <div className={classes.tableContainer}>
                 {
                    Object.entries(analystStockRecommendation).map(([key, value]) => {
                        if(key.toLowerCase() !== "symbol") {
                            const dataItem = {key: key, value: value};
                            return <TabularData data={dataItem}/>;
                        }
                    })
                 }
             </div>
          </>):
          <div>
              <Typography gutterBottom variant="overline">
                  No analyst views available for this company
              </Typography>
          </div>
          }
      </>
    );
};

export default AnalystView;