import React, {useEffect, useState} from 'react';

import CompanyProfile from "./CompanyProfile";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import {formatNumberReadable} from "../../utils";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
    },
    bodyContainer: {
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    dividerMargin: {
        marginTop: '20px'
    },
    customTypographyH6: {
        fontWeight: "bold"
    }
}));

const Other = (props) => {
    const classes = useStyles();
    const state = useSelector(state => state);

    return (
        <>
            <div className="container__wrap">
                <div className={classes.bodyContainer}>
                    <div>
                        <Typography gutterBottom variant="h6" classes={{h6:classes.customTypographyH6}}>
                            Earnings Date & Estimate
                        </Typography>
                        <div>Earnings Date: {state.companyEarningsCalendar?.data?.date}</div>
                        <div>EPS Estimated: {state.companyEarningsCalendar?.data?.epsEstimated}</div>
                        <div>Revenue Estimated (in millions): {formatNumberReadable(state.companyEarningsCalendar?.data?.revenueEstimated)}</div>
                        <div>Fiscal Date Ending: {state.companyEarningsCalendar?.data?.fiscalDateEnding}</div>
                    </div>
                    <div className={classes.dividerMargin}> <CompanyProfile /> </div>
                </div>
            </div>
        </>
    );
};

export default Other;