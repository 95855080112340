import numeral from "numeral";

const formatNumberForChart = (number) => {
    let finalValue = number;
    let signValue = Math.sign(number);
    let absValue = Math.abs(number)/1000000;
    let sign = "";
    if (signValue > 0 || signValue == 0)
        sign = "";
    else if (signValue < 0 || signValue == -0)
        sign = "-";
    if (absValue > 0)
        finalValue = sign + absValue;
    return Number(finalValue);
};

const formatNumberReadable = (value) => {
    if(!value) return "";
    let finalValue = value;
    let signValue = Math.sign(value);
    let absValue = Math.abs(value);
    absValue = absValue / 1000000;
    let sign = "";
    if (signValue > 0 || signValue == 0)
        sign = "";
    else if (signValue < 0 || signValue == -0)
        sign = "-";
    if (absValue > 0)
        finalValue = sign + numeral(absValue).format('0,0');
    return finalValue;
};

function getSafeRanges(dangerous) {
    const a = dangerous.commonAncestorContainer;
    // Starts -- Work inward from the start, selecting the largest safe range
    const s = new Array(0),
        rs = new Array(0);

    let xm;

    if (dangerous.startContainer !== a)
        for (let i = dangerous.startContainer; i !== a; i = i.parentNode) s.push(i);
    if (0 < s.length)
        for (let i = 0; i < s.length; i++) {
            const xs = document.createRange();
            if (i) {
                xs.setStartAfter(s[i - 1]);
                xs.setEndAfter(s[i].lastChild);
            } else {
                xs.setStart(s[i], dangerous.startOffset);
                xs.setEndAfter(
                    s[i].nodeType === Node.TEXT_NODE ? s[i] : s[i].lastChild
                );
            }
            rs.push(xs);
        }

    // Ends -- basically the same code reversed
    const e = new Array(0),
        re = new Array(0);
    if (dangerous.endContainer !== a)
        for (let i = dangerous.endContainer; i !== a; i = i.parentNode) e.push(i);
    if (0 < e.length)
        for (let i = 0; i < e.length; i++) {
            const xe = document.createRange();
            if (i) {
                xe.setStartBefore(e[i].firstChild);
                xe.setEndBefore(e[i - 1]);
            } else {
                xe.setStartBefore(
                    e[i].nodeType === Node.TEXT_NODE ? e[i] : e[i].firstChild
                );
                xe.setEnd(e[i], dangerous.endOffset);
            }
            re.unshift(xe);
        }

    // Middle -- the uncaptured middle
    if (0 < s.length && 0 < e.length) {
        xm = document.createRange();
        xm.setStartAfter(s[s.length - 1]);
        xm.setEndBefore(e[e.length - 1]);
    } else {
        return [dangerous];
    }

    // Concat
    rs.push(xm);
    const response = rs.concat(re);

    // Send to Console
    return response;
}

function highlightRange(range) {
    const newNode = document.createElement('div');
    newNode.setAttribute('style', 'background-color: yellow; display: inline;');
    newNode.setAttribute('class', 'one');
    range.surroundContents(newNode);
}

function highlightSelection() {
    const userSelection = window.getSelection().getRangeAt(0);
    const safeRanges = getSafeRanges(userSelection);
    for (let i = 0; i < safeRanges.length; i++) {
        highlightRange(safeRanges[i]);
    }
}

/**
 * Highlights a string within a Text node.
 * @param  {Text}     text     The text node in which to highlight.
 * @param  {string}   snippet  The substring within `text` to highlight.
 * @param  {html}     prefix   HTML string of the opening tag to insert for the highlighting.
 * @param  {html}     suffix   HTML string of the closing tag to insert for the highlighting.
 * @return {Element}           SPAN-Element that replaces `text` now.
 * @throws {Errror}   If `snippet` is not present in `text`.
 */
const highlight = (text, snippet, prefix, suffix) => {
    const parent = text.parentNode, string = text.textContent;
    const container = parent.ownerDocument.createElement('span');
    const at = string.indexOf(snippet); if (at < 0) { throw new Error('snippet not in text'); }
    container.innerHTML = encodeHtml(string.slice(0, at)) + prefix
        + encodeHtml(string.slice(at, at + snippet.length)) + suffix
        + encodeHtml(string.slice(at + snippet.length));
    parent.insertBefore(container, text); text.remove();
    return container;
};

/**
 * Reverts `highlight`.
 * @param  {Element}  replacement   The Element returned by `highlight`.
 * @param  {text}     originalText  The original Text.
 */
const unhighlight = (replacement, originalText) => {
    replacement.parentNode.insertBefore(originalText, replacement); replacement.remove();
};

/**
 * Replaces HTML control characters in a string with their escape entities.
 * @param  {string}  html  A string possibly containing control characters.
 * @return {string}        A string without any control characters, whose unescapeHtml() is the input.
 */
const encodeHtml = (html) => {
    return (html +'').replace(htmlEscapeRegExp, c => htmlEscapeObject[c]);
};
const htmlEscapeObject = { '&': '&amp;', '<': '&lt;', '>': '&gt;', "'": '&#39;', '"': '&quot;', '/': '&#47;', };
const htmlEscapeRegExp = new RegExp('['+ Object.keys(htmlEscapeObject).join('') +']', 'g');

const IANA_TZ = {
    'America/New_York': 'America/New_York',
    'America/Denver': 'America/Denver'
}

const getISODate = () => {
    const today = new Date();
    return today.toISOString();
};

const getTZDate = (tzFormat) => {
    const today = new Date();
    return today.toLocaleDateString('en-US', {
        timeZone: tzFormat
    });
};

const getTZTime = (tzFormat) => {
    const today = new Date();
    return today.toLocaleTimeString('en-US', {
        timeZone: tzFormat,
        hour: "numeric",
        minute: "numeric",
        hour12: false
    });
};

const parseDate = (dateValue, options) => {
  const parsedDate = new Date(dateValue);
  return parsedDate.toLocaleDateString("en-US", options);
};

export { highlightSelection, formatNumberForChart, formatNumberReadable,
    highlight, getTZDate, getTZTime, IANA_TZ, getISODate, parseDate };