import React, {useEffect, useState} from 'react';
import {Bar, BarChart, Brush, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {useSelector} from "react-redux";


const color = ["CornflowerBlue", "DarkSeaGreen", "purple", "violet", "brown", "pink", "Maroon", "Navy", "Olive", "Red", "teal", "lime", "aqua"]
const DEFAULT_NOT_OF_DATASET = 8;

const SimpleBarChart = (props) => {
    const state = useSelector(state => state);
    const { data, showLegend = true } = props;
    const [startPos, setStartPos] = useState(0);

    const legends = () => {
        const legendSet = new Set();
        data.map(entry => {
            Object.keys(entry).forEach(keyName => {
                if(keyName !== "key")
                    legendSet.add(keyName);
            })
        });
        return Array.from(legendSet);
    };

    const getBar = () => {
        const {stacked} = props;
        const keysArr = legends();
        const barArr= [];
        keysArr.forEach((item, index)=> {
            barArr.push(<Bar dataKey={item} stackId={stacked ? "a" : null} fill={color[index]} />)
        })
        return barArr;
    };

    useEffect(() => {
        if(data.length > DEFAULT_NOT_OF_DATASET) {
            setStartPos(data.length - DEFAULT_NOT_OF_DATASET)
        }
    }, [data]);

    const xAxisTickFormatter = (date) => {
        return date;
    }

    return (
        <>
            {data && data.length > 0 && (
            <ResponsiveContainer width="90%" height="100%">
                <BarChart
                    width={500}
                    height={500}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={Object.keys(data[0])[0]} />
                    <YAxis/>
                    <Tooltip contentStyle={{opacity: '0.8', fontSize:'12px', fontWeight: 'bold', border: 'solid 1px'}} />
                    { showLegend && <Legend/> }
                    {getBar()}
                    <Brush dataKey={Object.keys(data[0])[0]} startIndex={startPos}>
                        <BarChart>
                            {getBar()}
                        </BarChart>
                    </Brush>
                </BarChart>
            </ResponsiveContainer>
            )}
        </>
    );
}

export default SimpleBarChart;