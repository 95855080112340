import * as types from '../constants/actionTypes';
import { get, putpost } from './apiClient';
import {getRandomArbitrary} from "../constants/CommonUtils";

export const getSymbols = name => {
    return (dispatch, getState) => {
        var endpoint = '/api/symbols/'+name;

        get(endpoint, "GET").then((res) => {
            dispatch(addSymbol(res));
        })
        .catch(err => {
            console.log(err);
        });
    };
};

export const getSymbolSelected = () => {
    return get('/api/saved_symbol', "GET").then((res) => {
        return res;
    })
    .catch(err => {
        console.log(err);
    });
};

export const cacheSymbol = (symbol) => {
  return putpost('/api/symbol', 'PUT', symbol).then((res) => {
    return res;
  }).catch(err =>{
    console.log(err);
  })
};

export const getAllFinancialStatements = async (symbol, latest) => {
    var endpoint = '/api/corefinancials/get_all_financial_statements/'+ symbol + '/'+ latest;
    let response = await get(endpoint, "GET");
    const data = await response.json();
    if (response.status !== 200) throw Error(data.message);
    return data;
};

export const getFinancialStatements = async (symbol, latest) => {
    var endpoint = '/api/corefinancials/get_financial_statements/'+ symbol + '/'+ latest;
    let response = await get(endpoint, "GET");
    const data = await response.json();
    if (response.status !== 200) throw Error(data.message);
    return data;
};

export const getAllReportedDates = async (symbol) => {
    var endpoint = '/api/corefinancials/get_all_date_reported/'+ symbol;
    let response = await get(endpoint, "GET");
    const data = await response.json();
    if (response.status !== 200) throw Error(data.message);
    return data;
};

export const getSECFilingReport = async (statementData) => {
  var endpoint = '/api/corefinancials/get_sec_report_filing/'+encodeURIComponent(statementData.sec_filing_link);
  let response = await get(endpoint, "GET", true);
  const data = await response;
  if (response.status && response.status !== 200) {
    console.log("getSECFilingReport: " + data);
    throw Error(data);
  }
  return data;
};

export const updateUserSECSelection = async (secReport, selectedTextList, secUrl, userId, isDelete) => {
    const endpoint = '/api/corefinancials/save_user_sec_selection';
    const request = {"secReport" : secReport,
        "selectedTextList": selectedTextList,
        "secUrl": secUrl,
        "userId": userId,
        "isDelete": isDelete
    };
    let response = await putpost(endpoint, "POST", request, true);
    const data = await response;
    if (response.status && response.status !== 200) {
        console.log("save SEC Report Success");
        throw Error(data);
    }
    return data;
};

export const getUserSecSelection = async (secReport, secUrl, userId) => {
    const endpoint = '/api/corefinancials/get_user_sec_selection';
    const request = {"secReport" : secReport,
        "secUrl": secUrl,
        "userId": userId || ""
    };
    let response = await putpost(endpoint, "POST", request, false);
    const data = await response;
    if (response.status && response.status !== 200) {
        throw Error(data);
    }
    return data;
};

export const getSECReport = async (accessionNumber, cik, reportType) => {
  var endpoint = '/api/corefinancials/get_sec_report/'+ accessionNumber + '/'+ cik + '/' + reportType;
  let response = await get(endpoint, "GET", true);
  const data = await response;
  if (response.status && response.status !== 200) throw Error(data.message);
  return data;
}

export const postFeedback = async(feedback) => {
  var endpoint = '/api/feedback';  
  let response = await putpost(endpoint, 'POST', feedback);
  const message = await response;
  console.log("response form ui-api: "+message);
  if (!message) throw Error(message);
  return message;
}

export const getUser = async(userId, dispatch) => {
  var endpoint = '/api/user/'+ userId;
  let response = await get(endpoint, "GET", false);
  const data = await response;
  if (response.status && response.status !== 200) throw Error(data.message);
  dispatch(addUser(data));
  return data;
};

export const getUserFeedback = async(userId, dispatch) => {
    const endpoint = `/api/feedback/${userId}`;
    let response = await get(endpoint, "GET", false);
    const data = await response;
    if (response.status && response.status !== 200) throw Error(data.message);
    dispatch(addUserFeedback(data));
    return data;
};

export const getEarningsCalendar = async(symbol) => {
    const endpoint = '/api/earnings_calendar/'+ symbol;
    let response = await get(endpoint, "GET", false);
    const data = await response;
    if (response.status && response.status !== 200) throw Error(data.message);
    return data;
};

export const getRevenueByProductSegment = async(symbol, period, dispatch) => {
    const endpoint = `/api/corefinancials/get_all_revenue_by_product_segment/${symbol}/${period}`;
    let response = await get(endpoint, "GET", false);
    const data = await response;
    if (response.status && response.status !== 200) throw Error(data.message);
    dispatch(addRevenueByProductSegment(data));
    return data;
};

export const getRevenueByGeoSegment = async(symbol, period, dispatch) => {
    const endpoint = `/api/corefinancials/get_all_revenue_by_geo_segment/${symbol}/${period}`;
    let response = await get(endpoint, "GET", false);
    const data = await response;
    if (response.status && response.status !== 200) throw Error(data.message);
    dispatch(addRevenueByGeoSegment(data));
    return data;
};

export const getCompanyRatings = async(symbol, dispatch) => {
    const endpoint = `/api/corefinancials/get_rating/${symbol}`;
    let response = await get(endpoint, "GET", false);
    const data = await response;
    if (response.status && response.status !== 200) throw Error(data.message);
    dispatch(addCompanyRatings(data));
    return data;
};

export const getAnalystStockRecommendation = async(symbol, dispatch) => {
    const endpoint = `/api/corefinancials/get_analyst_stock_recommendation/${symbol}`;
    let response = await get(endpoint, "GET", false);
    const data = await response;
    if (response.status && response.status !== 200) throw Error(data.message);
    dispatch(addAnalystStockRecommendation(data));
    return data;
};

export const getAnalystEstimates = async(symbol, period, dispatch) => {
    const endpoint = `/api/corefinancials/get_analyst_estimates/${symbol}/${period}`;
    let response = await get(endpoint, "GET", false);
    const data = await response;
    if (response.status && response.status !== 200) throw Error(data.message);
    dispatch(addAnalystEstimates(data));
    return data;
};
const delay = ms => new Promise(res => setTimeout(res, ms));
export const getCompanySummary = async(symbol, dispatch) => {
    const endpoint = `/api/corefinancials/get_company_summary/${symbol}`;
    let response = await get(endpoint, "GET", false);
    const data = await response;
    if (response.status && response.status !== 200) throw Error(data.message);
    dispatch(addCompanySummary(data));
    return data;
};

export const getCompanyNews = async(symbol, dispatch) => {
    const endpoint = `/api/corefinancials/get_company_news/${symbol}`;
    let response = await get(endpoint, "GET", false);
    const data = await response;
    if (response.status && response.status !== 200) throw Error(data.message);
    dispatch(addCompanyNews(data));
    return data;
};

export const getMarketHours = async(dispatch) => {
    const endpoint = "/api/corefinancials/get_market_hours";
    let response = await get(endpoint, "GET", false);
    const data = await response;
    if (response.status && response.status !== 200) throw Error(data.message);
    dispatch(addMarketHours(data));
    return data;
};

export const getPrePostMarketTrade = async(symbol, dispatch) => {
    const endpoint = `/api/corefinancials/get_pre_post_market_trade/${symbol}`;
    let response = await get(endpoint, "GET", false);
    const data = await response;
    if (response.status && response.status !== 200) throw Error(data.message);
    dispatch(addPrePostMarketTrade(data));
    return data;
};

export const getAllSymbols = async(dispatch) => {
    const endpoint = "/api/corefinancials/get_all_symbols";
    let response = await get(endpoint, "GET", false);
    const data = await response;
    if (response.status && response.status !== 200) throw Error(data.message);
    dispatch(addSymbols(data));
    return data;
};

export const subscribeToNewSymbolWS = async(symbolFrom, symbolTo) => {
    const endpoint = `/api/corefinancials/subscribe_to_new_symbol/${symbolFrom ? symbolFrom: "undefined"}/${symbolTo}`;
    let response = await get(endpoint, "GET", false);
    return response;
};

/***************************************************************************************************
 * BELOWS are actions for redux
 ***************************************************************************************************/

export const addSymbols = (payload) => {
    return {
        type: types.ADD_SYMBOLS,
        payload: payload
    }
};

export const addPrePostMarketTrade = (payload) => {
    return {
        type: types.PRE_POST_MARKET_TRADE,
        payload: payload
    }
};

export const addMarketHours = (payload) => {
    return {
        type: types.MARKET_HOURS,
        payload: payload
    }
};

export const addCompanyNews = (payload) => {
    return {
        type: types.COMPANY_NEWS,
        payload: payload
    }
};

export const addCompanySummary = (payload) => {
    return {
        type: types.COMPANY_SUMMARY,
        payload: payload
    }
};

export const addAnalystEstimates = (payload) => {
    return {
        type: types.ANALYST_ESTIMATES,
        payload: payload
    }
};

export const addAnalystStockRecommendation = (payload) => {
    return {
        type: types.ANALYST_STOCK_RECOMMENDATION,
        payload: payload
    }
};

export const addCompanyRatings = (payload) => {
    return {
        type: types.COMPANY_RATINGS,
        payload: payload
    }
};

export const addRevenueByProductSegment = (payload) => {
    return {
        type: types.REVENUE_BY_PRODUCT_SEGMENT,
        payload: payload
    }
};

export const addRevenueByGeoSegment = (payload) => {
    return {
        type: types.REVENUE_BY_GEO_SEGMENT,
        payload: payload
    }
};

export const addSECReport = payload => ({
    type: types.ADD_SEC_REPORT,
    payload: payload
});

export const removeSECReport = payload => ({
    type: types.REMOVE_SEC_REPORT,
    payload: payload
});

export const saveUserSelectedSECReport = payload => ({
    type: types.SAVE_USER_SELECTED_SEC_REPORT,
    payload: payload
});

export const addUserSelection = payload => ({
    type: types.ADD_USER_SELECTION,
    payload: payload
});

export const deleteUserSelection = payload => ({
    type: types.DELETE_USER_SELECTION,
    payload: payload
});

export const addSymbol = payload => ({
    type: types.ADD_SYMBOL,
    payload: payload
});

export const addAllFinancialStatementHistory = (payload, dispatch) => {
    dispatch(addAllIncomeHistory(payload.incomeStatementHistory));
    dispatch(addAllBalanceSheetHistory(payload.balanceSheetStatementHistory));
    dispatch(addAllCashFlowHistory(payload.cashFlowStatementHistory));
};

export const addAllFinancialStatement = (payload, dispatch) => {
    dispatch(addUpdateIncome(payload.incomeStatement));
    dispatch(addUpdateBalanceSheet(payload.balanceSheetStatement));
    dispatch(addUpdateCashFlow(payload.cashFlowStatement));
    dispatch(addIncomeHistory(payload.incomeStatementHistory));
    dispatch(addBalanceSheetHistory(payload.balanceSheetStatementHistory));
    dispatch(addCashFlowHistory(payload.cashFlowStatementHistory));
    dispatch(addCurrencyReported(payload.currencyReported));
};

export const addAllDateReported = payload => ({
    type: types.ADD_ALL_DATE_REPORTED,
    payload: payload
});

export const addUpdateIncome = payload => ({
    type: types.ADD_UPDATE_INCOME,
    payload: payload
});

export const updateIncomeRevenue = payload => ({
    type: types.UPDATE_INCOME_REVENUE,
    payload: payload
});

export const updateIncomeGrossProfit = payload => ({
    type: types.UPDATE_INCOME_GROSS_PROFIT,
    payload: payload
});

export const updateIncomeSGA = payload => ({
    type: types.UPDATE_INCOME_SGA,
    payload: payload
});

export const updateIncomeResearchAndDevelopment = payload => ({
    type: types.UPDATE_INCOME_RESEARCH_AND_DEVELOPMENT,
    payload: payload
});

export const addAllIncomeHistory = payload => ({
    type: types.ADD_ALL_INCOME_HISTORY,
    payload: payload
});
export const addAllBalanceSheetHistory = payload => ({
    type: types.ADD_ALL_BALANCE_SHEET_HISTORY,
    payload: payload
});
export const addAllCashFlowHistory = payload => ({
    type: types.ADD_ALL_CASH_FLOW_HISTORY,
    payload: payload
});

export const addIncomeHistory = payload => ({
    type: types.ADD_INCOME_HISTORY,
    payload: payload
});

export const addBalanceSheet = payload => ({
    type: types.ADD_BALANCE_SHEET,
    payload: payload
});

export const addUpdateBalanceSheet = payload => ({
    type: types.ADD_UPDATE_BALANCE_SHEET,
    payload: payload
});

export const addBalanceSheetHistory = payload => ({
    type: types.ADD_BALANCE_SHEET_HISTORY,
    payload: payload
});

export const addUpdateCashFlow = payload => ({
    type: types.ADD_UPDATE_CASH_FLOW,
    payload: payload
});

export const addCashFlowHistory = payload => ({
    type: types.ADD_CASH_FLOW_HISTORY,
    payload: payload
});

export const updateCurrentStatementTab = payload => ({
    type: types.CURRENT_STATEMENT,
    payload: payload
});

export const onDateSelection = payload => ({
    type: types.ON_DATE_SELECTION,
    payload: payload
});

export const addCurrencyReported = payload => ({
    type: types.ADD_CURRENCY_REPORTED,
    payload: payload
});

export const addUpdateChartIndex = payload => ({
    type: types.ADD_UPDATE_CHART_INDEX,
    payload: payload
});

export const addFinancialStatementsHelpDesc = payload => ({
    type: types.ADD_FINANCIAL_STATEMENTS_HELP_DESC,
    payload: payload
});

export const addCompanyProfile = payload => ({
    type: types.ADD_COMPANY_PROFILE,
    payload: payload
});

export const addCompanyEarningsCalendar = payload => ({
    type: types.ADD_COMPANY_EARNINGS_CALENDAR,
    payload: payload
});

export const addUserAuthenticationStatus = (payload, dispatch) => {
    let userAuthenticationStatusAction = {
        type: types.ADD_USER_AUTHENTICATION_STATUS,
        payload: payload
    }
    dispatch(userAuthenticationStatusAction);
};

export const addUser = (payload) => {
    return {
        type: types.ADD_USER,
        payload: payload
    }
};

export const addUserFeedback = (payload) => {
    return {
        type: types.USER_FEEDBACK,
        payload: payload
    }
};

export const feedbackDone = (payload) => {
    return {
        type: types.USER_FEEDBACK_DONE,
        payload: payload
    }
};

export const addUserId = (payload) => {
    return {
        type: types.ADD_USER_ID,
        payload: payload
    }
};

export const logOut = (dispatch) => {
    dispatch({ type: types.LOG_OUT });
};

export const reduxStoreInitialize = (dispatch) => {
    dispatch({ type: types.REDUX_STORE_INITIALIZE });
};