import {
    ADD_USER_AUTHENTICATION_STATUS,
    ADD_USER,
    ADD_USER_ID,
    LOG_OUT,
    USER_FEEDBACK,
    USER_FEEDBACK_DONE
} from '../constants/actionTypes';
import * as constants from "../constants/CommonConstants";


const user = (state = {}, action) => {
  switch(action.type){
    case ADD_USER_AUTHENTICATION_STATUS:
        return {
            ...state,
            isAuthenticated: action.payload
        }
    case ADD_USER:
        return {
            ...state,
            profile: action.payload
        }
    case ADD_USER_ID:
        return {
            ...state,
            userId: action.payload
        }
    case LOG_OUT:
          return {
              ...state,
              isAuthenticated: false
          }
    case USER_FEEDBACK:
          return {
              ...state,
              feedback: action.payload
          }
    case USER_FEEDBACK_DONE:
          return {
              ...state,
              feedback_done: action.payload
          }
    default:
        return state;
  }
};

export default user;