import {ADD_SYMBOLS, CURRENT_STATEMENT, ON_DATE_SELECTION} from '../constants/actionTypes';

export const application = (state = {}, action) => {
    switch (action.type) {
        case CURRENT_STATEMENT:
            return {
                ...state,
                currentStatement: action.payload
            }
        case ON_DATE_SELECTION:
            return {
                ...state,
                onDateSelection: action.payload
            }
        default:
            return {
                ...state,
                isPublic: process.env.REACT_APP_PUBLIC ?? false,
                showPrivateLinks: process.env.REACT_APP_SHOW_PRIVATE_LINKS
            };
    }
};

export const application_symbols = (state = {}, action) => {
    switch (action.type) {
        case ADD_SYMBOLS:
            return {
                ...state,
                allSymbols: action.payload
            }
        default:
            return state;
    }
};