import React, { useEffect, useState } from 'react';
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {ListGroup, ListGroupItem, PopoverBody, UncontrolledPopover} from "reactstrap";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {Chip} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import {formatNumberForChart, formatNumberReadable, parseDate} from "../../utils";
import {SummaryInfo} from "../../constants/CommonUtils";



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    tableContainer: {
        display: "table"
    },
    tableRow: {
        display: "table-row"
    },
    tableColumn: {
        display: "table-cell"
    },
    tableContainerIntraday: {
        display: "table",
        float: "left"
    },
    tableContainerAfterHours: {
        display: "table",
        float: "right"
    },
    tableColumnName: {
        display: "table-cell",
        width: "70%"
    },
    tableColumnValue: {
        display: "table-cell",
        width: "30%",
        fontWeight: "bold"
    },
    tableColumnIcon: {
        marginLeft: "10px"
    },
    muiSvgIconRoot: {
        width: "17px",
        height: "17px",
        verticalAlign: "text-top",
        cursor: "pointer",
        opacity: "0.5"
    },
    otherTradingActivitiesContainer: {
        marginTop: "10px"
    },
    priceChanges: {
        marginLeft: "10px"
    },
    customTypographySubtitle2: {
        fontWeight: "bold"
    },
    customTypographyButton: {
        fontWeight: "bold"
    },
    customTypographyH4: {
        fontWeight: "bold"
    },
    customTypographyH6: {
        fontWeight: "bold"
    },
    customTypographyCaption: {
        fontSize: "10px",
        display: "block"
    }
}));

const TabularData = ({ data }) => {
    const classes = useStyles();

    return (
     <>
         <div className={classes.tableContainer}>
             <div className={classes.tableRow}>
                 <div className={classes.tableColumnName}>
                     <Typography variant="subtitle2" classes={{subtitle2: classes.customTypographySubtitle2}}>
                         {data.name}
                     </Typography>
                 </div>
                 <div className={classes.tableColumnValue}>
                     <Typography variant="button" classes={{button: classes.customTypographyButton}}>{data.value}</Typography>
                 </div>
                 <div className={classes.tableColumnIcon}>
                     <InfoOutlinedIcon classes={{root: classes.muiSvgIconRoot}} id={"popover_"+data.id} />
                 </div>
             </div>
         </div>
         <UncontrolledPopover trigger="legacy" placement="top" target={"popover_"+data.id}>
             <PopoverBody>
                { SummaryInfo[data.id] }
             </PopoverBody>
         </UncontrolledPopover>
     </>
    );
};

const Summary = (props) => {
    const classes = useStyles();
    const state = useSelector(state => state);
    const [customSummary, setCustomSummary] = useState(null);
    const [marketOpen, setMarketOpen] = useState(false);

    const customizeSummaryData = (rawCompanySummary) => {
        let customSummaryData = {};
        if(rawCompanySummary) {
            customSummaryData.previousClose = {
                id: "previousClose",
                name: "Previous Close",
                value: rawCompanySummary.previousClose ? rawCompanySummary.previousClose: ""
            };
            customSummaryData.open = {
                id: "open",
                name: "Open",
                value: rawCompanySummary.open ? rawCompanySummary.open: ""
            };
            customSummaryData.bid = {
                id: "bid",
                name: "Bid",
                value: rawCompanySummary.bidPrice ? `${rawCompanySummary.bidPrice} X ${rawCompanySummary.bidSize}`: ""
            };
            customSummaryData.ask = {
                id: "ask",
                name: "Ask",
                value: rawCompanySummary.askPrice ? `${rawCompanySummary.askPrice} X ${rawCompanySummary.askSize}`: ""
            };
            customSummaryData.lastSale = {
                id: "lastSale",
                name: "Last Sale",
                value: rawCompanySummary.lastSalePrice ? `${rawCompanySummary.lastSalePrice} X ${rawCompanySummary.lastSaleSize}`: ""
            };
            customSummaryData.dayRange = {
                id: "dayRange",
                name: "Day's Range",
                value: rawCompanySummary.dayLow ? `${rawCompanySummary.dayLow} - ${rawCompanySummary.dayHigh}`: ""
            };
            customSummaryData.marketCap = {
                id: "marketCap",
                name: "Market Cap (In millions)",
                value: rawCompanySummary.marketCap ? formatNumberReadable(rawCompanySummary.marketCap): ""
            };
            customSummaryData.eps = {
                id: "eps",
                name: "EPS",
                value: rawCompanySummary.eps ? rawCompanySummary.eps: ""
            };
            customSummaryData.pe = {
                id: "pe",
                name: "PE",
                value: rawCompanySummary.pe ? rawCompanySummary.pe : ""
            };
            customSummaryData.sharesOutstanding = {
                id: "sharesOutstanding",
                name: "Shares Outstanding (In millions)",
                value: rawCompanySummary.sharesOutstanding ? formatNumberReadable(rawCompanySummary.sharesOutstanding): ""
            };
            customSummaryData.earningsDate = {
                id: "earningsDate",
                name: "Earnings Date",
                value: rawCompanySummary.earningsAnnouncement ? parseDate(rawCompanySummary.earningsAnnouncement, { timeZone: "UTC" }): ""
            };
            customSummaryData.lastUpdated = rawCompanySummary.lastUpdated;
            setCustomSummary(customSummaryData);
        }else
            setCustomSummary(null);
    };

    useEffect(() => {
        if(state.companyInfo?.company_summary && state.companyInfo?.company_summary?.length > 0) {
            customizeSummaryData(state.companyInfo?.company_summary[0]);
        }
        setMarketOpen(state.companyInfo?.market_hours?.isTheStockMarketOpen)
    },[state.companyInfo?.company_summary]);

    return (
      <>
          { customSummary ? (
          <>
              <Typography gutterBottom variant="h6" classes={{h6:classes.customTypographyH6}}>
                  Intraday Market Data and Summary
                  {!marketOpen && (
                      <Typography gutterBottom color="error" variant="button"> (Market Closed) </Typography>
                  )}
                  <div>
                      <Typography gutterBottom variant="caption" classes={{caption: classes.customTypographyCaption}}>
                          Regular Market Hours (New-York ET): Monday to Friday - 9:30 AM to 4:00 PM.
                          Pre Market Hours: 4:00 AM to 9:30 AM.  After Hours: 4:00 PM to 8:00 PM
                      </Typography>
                      <Typography gutterBottom variant="caption" classes={{caption: classes.customTypographyCaption}}>
                          Currency Reported: {state.currencyReported.value}
                      </Typography>
                  </div>
              </Typography>
              <Grid container spacing={4}>
                  <Grid key={0} item xs={6}>
                      <ListGroup flush>
                          <ListGroupItem>
                              <TabularData data={customSummary.previousClose} />
                          </ListGroupItem>
                          <ListGroupItem>
                              <TabularData data={customSummary.open} />
                          </ListGroupItem>
                          <ListGroupItem>
                              <TabularData data={customSummary.bid} />
                          </ListGroupItem>
                          <ListGroupItem>
                              <TabularData data={customSummary.ask} />
                          </ListGroupItem>
                          <ListGroupItem>
                              <TabularData data={customSummary.lastSale} />
                          </ListGroupItem>
                          <ListGroupItem>
                              <TabularData data={customSummary.dayRange} />
                          </ListGroupItem>
                      </ListGroup>
                  </Grid>
                  <Grid key={1} item xs={6}>
                      <ListGroup flush>
                          <ListGroupItem>
                              <TabularData data={customSummary.marketCap} />
                          </ListGroupItem>
                          <ListGroupItem>
                              <TabularData data={customSummary.eps} />
                          </ListGroupItem>
                          <ListGroupItem>
                              <TabularData data={customSummary.pe} />
                          </ListGroupItem>
                          <ListGroupItem>
                              <TabularData data={customSummary.sharesOutstanding} />
                          </ListGroupItem>
                          <ListGroupItem>
                              <TabularData data={customSummary.earningsDate} />
                          </ListGroupItem>
                      </ListGroup>
                  </Grid>
              </Grid>
          </>
              ): (
                  <div>
                      <Typography gutterBottom variant="overline">
                          No market data available for this company
                      </Typography>
                  </div>
          )}
      </>
    );
}

export default Summary;