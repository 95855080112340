import { company_info, symbol } from './reducers/company';
import {
    income,
    income_history,
    balance_sheet,
    balance_sheet_history,
    cash_flow,
    cash_flow_history,
    all_financial_history,
    all_date_reported, chart,
    financial_statements_help_desc, sec_report,
    company_profile, currency_reported, company_earnings_calendar
} from './reducers/financial';
import { application, application_symbols } from './reducers/application';
import user from './reducers/user';
import themeReducer from './reducers/themeReducer';
import rtlReducer from './reducers/rtlReducer';
import { combineReducers } from 'redux';
import { REDUX_STORE_INITIALIZE } from './constants/actionTypes';


const appReducer = combineReducers({
    symbol,
    incomeStatement: income,
    incomeStatementHistory: income_history,
    balanceSheet: balance_sheet,
    balanceSheetHistory: balance_sheet_history,
    cashFlow: cash_flow,
    cashFlowHistory: cash_flow_history,
    financialStatementsHelpDesc: financial_statements_help_desc,
    secReport: sec_report,
    allDateReported: all_date_reported,
    companyProfile: company_profile,
    companyEarningsCalendar: company_earnings_calendar,
    currencyReported: currency_reported,
    allFinancialHistory: all_financial_history,
    theme: themeReducer,
    rtl: rtlReducer,
    user: user,
    chart,
    application,
    application_symbols,
    companyInfo: company_info
});

const rootReducer = (state, action) => {
    // initialize redux store
    if (action.type === REDUX_STORE_INITIALIZE) {
        state.symbol = {};
        state.companyInfo = {};
        state.incomeStatement = {};
        state.incomeStatementHistory = {};
        state.balanceSheet = {};
        state.balanceSheetHistory = {};
        state.cashFlow = {};
        state.cashFlowHistory = {};
        state.financialStatementsHelpDesc = {};
        state.secReport = {};
        state.allDateReported = {};
        state.companyProfile = {};
        state.companyEarningsCalendar = {};
        state.currencyReported = {};
        state.allFinancialHistory = {};
        state.user = {};
        state.chart = {};
        state.application = {};
        state.companyInfo = {};
    }
    return appReducer(state, action);
};

export default rootReducer;