import {getTZDate, getTZTime, IANA_TZ} from "../utils";

export const dataExist = (dataSet) => {
  var dataExist = false;
  for(var dataItem of dataSet){ 
      if(dataItem.value > 0) {
        return true;
        break;
      }
    }
  return false;
};

export const isIntraday = () => {
    const time_ET = getTZTime(IANA_TZ["America/New_York"]);
    return time_ET >= "09:30" && time_ET <= "16:00";
};

export const isExtendedHours = () => {
    const time_ET = getTZTime(IANA_TZ["America/New_York"]);
    //pre-market hours
    if(time_ET >= "08:00" && time_ET < "09:30")
        return true;
    //after hours
    if(time_ET >= "16:00" && time_ET < "20:00")
        return true;
};

export const isMarketHolidayToday = (marketHolidays) => {
    const today = getTZDate(IANA_TZ["America/New_York"]);
    const dateParsed = today.split("/");//M/D/YYYY
    const day = dateParsed[1] < 10 ? `0${dateParsed[1]}` : dateParsed[1];
    //check US weekends (SAT and SUN)
    const currentDay = new Date().getDay();
    if (currentDay === 0 || currentDay === 6)
        return true;
    const month = dateParsed[0] < 10 ? `0${dateParsed[0]}` : dateParsed[0];
    const dateFormatted = `${dateParsed[2]}-${month}-${day}`;
    for (const holidayYear of marketHolidays) {
        if (holidayYear.year === Number(dateParsed[2])) {
            const hy = holidayYear;
            for (const [key, value] of Object.entries(holidayYear)) {
                if (value === dateFormatted) return true;
            }
        }
    }
    return false;
};

export const extendedHoursClosedDuration = () => {
    const time_ET = getTZTime(IANA_TZ["America/New_York"]);
    return (time_ET > "20:00" && time_ET < "23:59") || (time_ET > "00:00" && time_ET < "08:00");
};

export const getRandomArbitrary = (min, max) => {
    return Math.random() * (max - min) + min;
};

export const SummaryInfo = {
    open: "Open price is the first transaction price that occurs when the market opens for regular trading hours." +
        " The opening price may be different from the previous day's closing price due to after-hours or pre-market trading.",
    previousClose: "Closing price of this stock on the last trading day on regular marker hours.",
    bid: "Highest price a buyer is willing to pay for a particular security, such as a stock, bond, or commodity." +
        " Shown as Price X Quantity.",
    ask: "Lowest price a seller is willing to sell a particular security, such as a stock, bond, or commodity." +
        " Shown as Price X Quantity.",
    lastSale: "Price at which the last trade occurred or executed. Shown as Price X Quantity.",
    dayRange: " Range of price from lowest to highest at which a particular security, such as a stock, " +
        "has traded during a single trading day on regular market hours.",
    marketCap: "Market capitalization is a measure of the total market value of a company's outstanding shares of stock at the moment." +
        " It is calculated by multiplying the current market price of one share by the total number of outstanding shares.",
    eps: "Earnings Per Share (EPS) is a financial metric that represents the portion of a " +
        "company's profit attributable to each outstanding share of common stock.",
    pe: "P/E stands for Price-to-Earnings ratio, which is a valuation metric used in finance to assess the relative" +
        " value of a company's stock. The P/E ratio is calculated by dividing the market price" +
        " per share of a stock by its earnings per share (EPS).",
    sharesOutstanding: "It's the total number of a company's shares of stock that are currently held by shareholders, " +
        "including institutional investors, insiders, and the general public. It represents the total ownership interest in the company.",
    earningsDate: "It's the scheduled date on which a publicly traded company is expected to release its quarterly or " +
        "annual financial results, including information on revenue, earnings, and other relevant financial metrics." +
        " Earnings dates are crucial events for investors, analysts, and traders as they provide insights into a " +
        "company's financial performance and can significantly impact its stock price."
}

const filterByNameOrSymbol = (item, searchTextPrefix) => {
    let matchedName, matchedSymbol;
    if (item.name)
        matchedName = item.name.trim().toLowerCase().startsWith(searchTextPrefix.toLowerCase());
    if (item.symbol)
        matchedSymbol = item.symbol.trim().toLowerCase().startsWith(searchTextPrefix.toLowerCase());
    return matchedName || matchedSymbol;
};

const filterByNonAlphabets = (item) => {
    const regEx = /^[a-zA-Z]/;
    let matchedName, matchedSymbol;
    if (item.name)
        matchedName = regEx.test(item.name.trim().toLowerCase());
    if (item.symbol)
        matchedSymbol = regEx.test(item.symbol.trim().toLowerCase());
    if(!matchedName && !matchedSymbol)
        return true;
    return false;
};

export const findSymbols = function(searchText, allSymbols){
    let filteredSymbols = allSymbols.filter(item => filterByNameOrSymbol(item, searchText));
    let symbolList = searchSymbolOrName(searchText, filteredSymbols);
    return symbolList;
};

const searchSymbolOrName = function(searchText, targetSymbolsCache, symbolList) {
    let localSymbolList = symbolList && symbolList.length > 0 ? symbolList : [];

    for (const item of targetSymbolsCache) {
        if (localSymbolList.length <= 10) {
            if (!localSymbolList.find(({symbol}) => symbol === item.symbol)) {
                localSymbolList.push(
                    {
                        'symbol': item.symbol,
                        'name': item.name,
                        'exchange': item.exchangeShortName
                    }
                );
            }

            if (!localSymbolList.find(({symbol}) => symbol === item.symbol)) {
                localSymbolList.push(
                    {
                        'symbol': item.symbol,
                        'name': item.name,
                        'exchange': item.exchangeShortName
                    }
                );
            }
        } else {
            break;
        }
    }

    let premiumSymbols = [];
    try {
        localSymbolList.forEach((symbol, index) => {
            if (symbol.exchange && (symbol.exchange.toLowerCase() === "nasdaq" || symbol.exchange.toLowerCase() === "nyse")) {
                premiumSymbols.push(symbol);
                localSymbolList.splice(index, 1);
            }
        });
    } catch (err) {
        throw err;
    }

    let prioritizedSymbols = premiumSymbols;
    localSymbolList.forEach(symbol => {
        prioritizedSymbols.push(symbol);
    });

    return prioritizedSymbols;
};

