export const ADD_SYMBOL = 'ADD_SYMBOL';
export const GET_SYMBOL = 'GET_SYMBOL';
export const ADD_UPDATE_INCOME = 'ADD_UPDATE_INCOME';
export const UPDATE_INCOME_REVENUE = 'UPDATE_INCOME_REVENUE';
export const UPDATE_INCOME_GROSS_PROFIT = 'UPDATE_INCOME_GROSS_PROFIT';
export const UPDATE_INCOME_SGA = 'UPDATE_INCOME_SGA';
export const UPDATE_INCOME_RESEARCH_AND_DEVELOPMENT = 'UPDATE_INCOME_RESEARCH_AND_DEVELOPMENT';
export const ADD_ALL_INCOME_HISTORY = 'ADD_ALL_INCOME_STATEMENT_HISTORY';
export const ADD_ALL_BALANCE_SHEET_HISTORY = 'ADD_ALL_BALANCE_SHEET_HISTORY';
export const ADD_ALL_CASH_FLOW_HISTORY = 'ADD_ALL_CASH_FLOW_HISTORY';
export const ADD_INCOME_HISTORY = 'ADD_INCOME_STATEMENT_HISTORY';
export const ADD_BALANCE_SHEET = 'ADD_BALANCE_SHEET';
export const ADD_UPDATE_BALANCE_SHEET = 'ADD_UPDATE_BALANCE_SHEET';
export const ADD_BALANCE_SHEET_HISTORY = 'ADD_BALANCE_SHEET_HISTORY';
export const ADD_UPDATE_CASH_FLOW = 'ADD_CASH_FLOW';
export const ADD_CASH_FLOW_HISTORY = 'ADD_CASH_FLOW_HISTORY';
export const ADD_ALL_DATE_REPORTED = 'ADD_ALL_DATE_REPORTED';
export const ADD_UPDATE_CHART_INDEX = 'ADD_UPDATE_CHART_INDEX';
export const ADD_FINANCIAL_STATEMENTS_HELP_DESC = 'ADD_FINANCIAL_STATEMENTS_HELP_DESC';
export const ADD_USER_AUTHENTICATION_STATUS = 'ADD_USER_AUTHENTICATION_STATUS';
export const LOG_OUT = 'LOG_OUT';
export const REDUX_STORE_INITIALIZE = 'REDUX_STORE_INITIALIZE';
export const ADD_SEC_REPORT = 'ADD_SEC_REPORT';
export const REMOVE_SEC_REPORT = 'REMOVE_SEC_REPORT';
export const SAVE_USER_SELECTED_SEC_REPORT = 'SAVE_USER_SELECTED_SEC_REPORT';
export const DELETE_USER_SELECTION = 'DELETE_USER_SELECTION';
export const ADD_USER_SELECTION = 'ADD_USER_SELECTION';
export const ADD_COMPANY_PROFILE = 'ADD_COMPANY_PROFILE';
export const ADD_COMPANY_EARNINGS_CALENDAR = 'ADD_COMPANY_EARNINGS_CALENDAR';
export const ADD_CURRENCY_REPORTED = 'ADD_CURRENCY_REPORTED';
export const CURRENT_STATEMENT = 'CURRENT_STATEMENT';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_ID = 'ADD_USER_ID';
export const USER_FEEDBACK = "USER_FEEDBACK";
export const USER_FEEDBACK_DONE = "USER_FEEDBACK_DONE";
export const ON_DATE_SELECTION = 'ON_DATE_SELECTION';
export const REVENUE_BY_PRODUCT_SEGMENT = 'REVENUE_BY_PRODUCT_SEGMENT';
export const REVENUE_BY_GEO_SEGMENT = 'REVENUE_BY_GEO_SEGMENT';
export const COMPANY_RATINGS = 'COMPANY_RATINGS';
export const ANALYST_STOCK_RECOMMENDATION = 'ANALYST_STOCK_RECOMMENDATION';
export const ANALYST_ESTIMATES = 'ANALYST_ESTIMATES';
export const COMPANY_SUMMARY = 'COMPANY_SUMMARY';
export const COMPANY_NEWS = 'COMPANY_NEWS';
export const MARKET_HOURS = 'MARKET_HOURS';
export const PRE_POST_MARKET_TRADE = 'PRE_POST_MARKET_TRADE';
export const ADD_SYMBOLS = 'ADD_SYMBOLS';