import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import parse from 'html-react-parser';
import $ from 'jquery';
import { v4 as uuidv4 } from 'uuid';

import '@easylogic/react-summernote/dist/index.css'

import SimpleSpinner from '../common/SimpleSpinner';
import {
    getSECFilingReport,
    addSECReport,
    saveUserSelectedSECReport,
    getUserSecSelection,
    updateUserSECSelection, addUserSelection
} from '../../actions';
import { useDispatch, useSelector } from "react-redux";
import Tooltip from '@material-ui/core/Tooltip';
import useAuth from "../../hooks/useAuth";
import {NavLink, Outlet, useLocation} from "react-router-dom";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MySelection from "./MySelection";


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  navContainer: {
    backgroundColor: "#f2f4f7",
    paddingTop: "25px",
    marginLeft: "20%",
    marginRight: "20%"
  },
  subNavActive: {
    fontWeight: "bold",
    fontSize: "14px",
    borderBottom: "2px solid black"
  },
  subNavLink: {
    display: "inline-block",
    marginLeft: "100px"
  },
  subNavAlign: {
    marginLeft: "25px",
  },
  bodyContainer: {
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  errorContainer: {
    marginLeft: "8%"
  },
  card : {
    marginLeft: 15,
    display: 'inline-block',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  description: {
    fontSize: 12,
  },
  section: {
    marginTop: 10,
  }
}));

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
};

const HIGHLIGHT_ERR_MSG = "Please select only titles and statements. Also do not select already highlighted section.";
const HIGHLIGHT_ERR_MSG_INVALID_NUMBER_OF_WORDS = "Please select 10 or more words.";
const TOTAL_NUMBER_OF_WORDS = 10;

const QuarterlyAndAnnualReport = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const [loading, setLoading] = React.useState(false);
    const [secLimitRequestMessage, setSecLimitRequestMessage] = React.useState("");
    const [selectedSecText, setSelectedSecText] = React.useState([]);
    const [highlightedSecReport, setHighlightedSecReport] = React.useState();
    const [invalidSelection, setInvalidSelection] = useState(false);
    const [invalidMessage, setInvalidMessage] = useState("");
    //const [hideControl, setHideControl] = useState(false);

    let hideControl = false;
    const saveControl = useRef(null);

    let invalidNumberOfWords = true;
    const authenticated = useAuth();
    const locate = useLocation();


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
      if(!state.secReport.data){
        setLoading(true);
        setSecLimitRequestMessage("");
        const statementData = getStatementData();
        getSECFilingReport(statementData).then((res) => {
          dispatch(addSECReport(res));
          if(authenticated)
              getUserSecReportSelection(res);
          setLoading(false);
        }).catch(err => {
          showError(err);
        })
      }
    },[state.incomeStatement]);

    const getStatementData = () => {
        let currentStatement = "income";
        if(state.application.currentStatement)
            currentStatement = state.application.currentStatement;

        if(currentStatement?.toLowerCase() === "income")
            return state.incomeStatement.data;
        else if(currentStatement?.toLowerCase() === "balancesheet")
            return state.balanceSheet.data;
        else if(currentStatement?.toLowerCase() === "cashflow")
            return state.cashFlow.data;
    };

    const getSecFilingLink = () => {
        const statementData = getStatementData();
        return statementData.sec_filing_link;
    };

    const showError = (error) => {
      setLoading(false);
      if(error.message){
        let parsedErrorMessage = JSON.parse(error.message);
        if(parsedErrorMessage && parsedErrorMessage.message.includes("403"))
          setSecLimitRequestMessage("No report available for this company")
      }else{
        setSecLimitRequestMessage("Sorry! A report might be missing for this company or date." +
          " <br>Quaterly or Annual report is only available for US listed companies.");
      }
    };

    const getWordCount = (textSelected) => {
        //textSelected.match(/\S+/g).length;
        /*
        const pattern = new RegExp("[\\w-]+");
        const matcher = textSelected.match(pattern);
        let count = 0;
        while (matcher.find())
            count++;
        return count;
         */
    };

    const toggleInvalidSelection = (invalid) => {
        setInvalidSelection(invalid);
        if(invalid) {
            if(!invalidNumberOfWords)
                setInvalidMessage(HIGHLIGHT_ERR_MSG_INVALID_NUMBER_OF_WORDS);
            else
                setInvalidMessage(HIGHLIGHT_ERR_MSG);
        }
        else
            setInvalidMessage("");
    };

    const hideSaveControl = (hide) => {
        if(saveControl && saveControl.current)
            saveControl.current.style.display = hide ? "none": "inline-block";
    };

    const getSelectedText = () => {
        let selection = "";
        if (window.getSelection) {
            selection = window.getSelection();
        } else if (window.document.getSelection) {
            selection =window.document.getSelection();
        } else if (window.document.selection) {
            selection = window.document.selection.createRange().text;
        }
        const selectedText = selection.toString();
        const isSelectionValid = validateSelection(selection);
        if(!isSelectionValid) {
            toggleInvalidSelection(true);
        }else{
            toggleInvalidSelection(false);
        }
        //const $control = $("#control");
        //$control.hide();
        //setHideControl(true);
        hideSaveControl(true);

        if(selectedText && selectedSecText.length === 0) {
            const rect = selection.getRangeAt(0).getBoundingClientRect();
            //$control.show();
            hideSaveControl(false);
            const top = `calc(${rect.top + window.scrollY}px - 48px)`;
            const left = `calc(${rect.left}px + calc(${rect.width}px / 2) - 40px)`;
            //saveControl.current.style.display = "inline-block";
            saveControl.current.style.top = top;
            saveControl.current.style.left = left;
            document.body.appendChild(saveControl.current);
            setSelectedSecText(selectedText.split(/\n|\n\n/));
        }else
            setSelectedSecText([]);
        return selection;
    };

    const validateSelection = (selection) => {
        let valid = true, doTotalNumberOfValidWordsValidation = true;
        let totalNumberOfWords = 0;

        const range = selection.getRangeAt(0);
        const clonedSelection = range.cloneContents();
        const div = document.createElement('div');
        div.appendChild(clonedSelection);
        try {
            const selectedHtml = $(div.innerHTML);
            if (selectedHtml.find("tr td").length > 0 || selectedHtml.filter("tr").length > 0)
                valid = false;
            if (selectedHtml.find("mark.highlight").length > 0)
                valid = false;
        }catch (err){}

        const selectedText = selection.toString();
        if(selectedText && valid) {
            let selectedTextList = selectedText.split("\n\n");
            for(const text of selectedTextList){
                if(!text.trim()) {
                    valid = false;
                    break;
                }else {
                    const wordsList = text.split(" ");
                    totalNumberOfWords += wordsList.length;
                }
            }
        }
        if(!valid)
            doTotalNumberOfValidWordsValidation = false;

        if(selectedText && doTotalNumberOfValidWordsValidation && (totalNumberOfWords < TOTAL_NUMBER_OF_WORDS)) {
            valid = false;
            invalidNumberOfWords = false;
        }else{
            invalidNumberOfWords = true;
        }

        if(!selectedText)
            valid = false;
        return valid;
    };

    const saveSelection = (secSelection, isDelete = false) => {
        const currentUserSecSelection = secSelection? secSelection: selectedSecText;
        updateUserSECSelection(state.secReport.data, currentUserSecSelection,
            getSecFilingLink(), state.user?.profile?.username, isDelete).then(res => {
            dispatch(addSECReport(res));
            if(!isDelete) {
                const filteredCurrentUserSelections = currentUserSecSelection.filter(item => item);
                const formatedUserSelections = formatUserSelections(filteredCurrentUserSelections);
                dispatch(addUserSelection(formatedUserSelections));
            }
        }).catch(err => {
            showError(err);
        });
        hideSaveControl(true);
        setSelectedSecText([]);
    };

    const getUserSecReportSelection = (secData) => {
        getUserSecSelection(secData, getSecFilingLink(), state.user?.profile?.username).then(res => {
            dispatch(addSECReport(res.secReport));
            if(res.selection) {
                const formatedUserSelections = formatUserSelections(res.selection.split("|"));
                dispatch(saveUserSelectedSECReport(formatedUserSelections));
            }
        }).catch(err => {
            showError(err);
        })
    };

    const formatUserSelections = (userSelections) => {
        const newFormatedSelectionList = userSelections.map((item, index) => {
            return createUniqueUserSelection(item);
        });
        return newFormatedSelectionList;
    };

    const createUniqueUserSelection = (userSelection) => {
      return {selection: userSelection, id: uuidv4()};
    };

    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
    };

    useEffect(() => {
        setHighlightedSecReport(state.secReport.data);
    },[state.secReport.data && state.secReport.user_selection]);

    return (
        <div className={`${state.theme.className} ${state.rtl.direction}-support`} dir={state.rtl.direction}>
          <div className="container__wrap">
            <SimpleSpinner loading={loading} />
            <div className={classes.bodyContainer}>
              { !secLimitRequestMessage ? (
                  <>
                  { authenticated ? (
                      <>
                      <Tabs
                          value={value}
                          onChange={handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                      >
                          <Tab label="Financial Report" {...a11yProps(0)} />
                          <Tab label="My Selection" {...a11yProps(1)} />
                      </Tabs>
                      <TabPanel value={value} index={0}>
                          <div dangerouslySetInnerHTML={{ __html: highlightedSecReport }} onMouseUp={getSelectedText} />
                          <template>
                              <div id="control" ref={saveControl}>
                                  <Tooltip title={invalidMessage}>
                                    <span>
                                        <Button variant="contained" color="primary"
                                                href="#contained-buttons" onClick={() => saveSelection()}
                                                disabled={invalidSelection}
                                        >Save</Button>
                                    </span>
                                  </Tooltip>
                              </div>
                          </template>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                          <MySelection onSaveSecSelection={saveSelection}/>
                      </TabPanel>
                      </>
                ): (<div dangerouslySetInnerHTML={{ __html: highlightedSecReport }} />)
              }
              </>):(
                  <div className={classes.errorContainer}>
                    <Typography variant="overline" gutterBottom>
                      {parse(secLimitRequestMessage)}
                    </Typography>
                  </div>
                )
              }
            </div>
          </div>
        </div>
    );
}

export default QuarterlyAndAnnualReport;
