import * as constants from '../constants/CommonConstants';

export const get = async (path, method, isHtml) => {
	const response = await fetch(path, {
  		method: method
  	});
  	let res = "";
  	if(isHtml)
		res = await response.text();
	else
		res = await response.json();
	clearLocalStorage(res);
	if (response.status !== 200 || res === ""){
		if(isHtml) {
			throw Error(res);
		}
		else {
			throw Error(res ? res.message : "unexpected");
		}
	}
	return res;
};

export const putpost = async (path, method, req, isHtml) => {
	const response = await fetch(path, {
  		method: method, // or 'PUT'
  		body: JSON.stringify(req),
  		headers:{
    		'Content-Type': 'application/json'
  		}
  	});
	let res = "";
	if(isHtml)
		res = await response.text();
	else
		res = await response.json();
	clearLocalStorage(res);
	if (response.status !== 200)
		throw Error(res.message);
	return res;
};

var clearLocalStorage = function (res) {
	if(res.message === 'expired_token') {
		localStorage.removeItem(constants.USER_ID);
		localStorage.removeItem(constants.AUTHENTICATED);
	}
}