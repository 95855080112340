import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import CompanyGrowth from "./CompanyGrowth";
import * as constants from "../../constants/CommonConstants";
import {
    getAnalystEstimates,
    getAnalystStockRecommendation, getCompanyNews,
    getCompanyRatings, getCompanySummary, getMarketHours, getPrePostMarketTrade,
    getRevenueByGeoSegment,
    getRevenueByProductSegment
} from "../../actions";
import AnalystView from "./AnalystView";
import Summary from "./Summary";
import CompanyNews from "./CompanyNews";
import { getTZTime, IANA_TZ } from "../../utils";
import { EXTENDED_HOURS_POLLING_TIME, INTRADAY_POLLING_TIME } from "../../constants/CommonConstants";
import {
    extendedHoursClosedDuration,
    isExtendedHours,
    isIntraday,
    isMarketHolidayToday
} from "../../constants/CommonUtils";


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
    },
    bodyContainer: {
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    muiTabRoot: {
        textTransform: "none"
    },
    customMuiTabsRoot: {
        position: "fixed",
        backgroundColor: "#f2f4f7"
    },
    customMuiBoxRoot: {
        paddingTop: "70px"
    },
    subNavContainer: {
        backgroundColor: "#f2f4f7"
    },
    muiTabs_scrollButtonsDesktop: {
        '@media (max-width: 599.95px)':{
            display: 'flex'
        }
    },
    muiTabPanelRoot: {
        backgroundColor: "#f2f4f7"
    }
}));

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    const classes = useStyles();

    return (
        <div
            className={classes.muiTabPanelRoot}
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className={classes.customMuiBoxRoot} p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
};

const Market = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [tabValue, setTabValue] = React.useState(0);
    const state = useSelector(state => state);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        const reportType = localStorage.getItem(constants.REPORT_TYPE);
        const currentSymbol = state?.symbol?.data?.symbol;
        if(currentSymbol) {
            if(!state.companyInfo?.revenueByProductSegment)
                getRevenueByProductSegment(currentSymbol, reportType, dispatch);
            if(!state.companyInfo?.revenueByGeoSegment)
                getRevenueByGeoSegment(currentSymbol, reportType, dispatch);
            if(!state.companyInfo?.company_ratings)
                getCompanyRatings(currentSymbol, dispatch);
            if(!state.companyInfo?.analyst_stock_recommendation)
                getAnalystStockRecommendation(currentSymbol, dispatch);
            if(!state.companyInfo?.analyst_estimates)
                getAnalystEstimates(currentSymbol, reportType, dispatch);
            if(!state.companyInfo?.company_news)
                getCompanyNews(currentSymbol, dispatch);
        }
    },[state?.symbol?.data?.symbol]);

    return (
        <div className={`${state.theme.className} ${state.rtl.direction}-support`} dir={state.rtl.direction}>
            <div className="container__wrap">
                <div className={classes.subNavContainer}>
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        classes={{root: classes.customMuiTabsRoot, scrollButtonsDesktop: classes.muiTabs_scrollButtonsDesktop}}
                    >
                        <Tab classes={{root: classes.muiTabRoot}} label="Summary" {...a11yProps(0)} />
                        <Tab classes={{root: classes.muiTabRoot}} label="Historical Growth" {...a11yProps(1)} />
                        <Tab classes={{root: classes.muiTabRoot}} label="Analyst Views" {...a11yProps(2)} />
                        <Tab classes={{root: classes.muiTabRoot}} label="News" {...a11yProps(3)} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <Summary/>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <CompanyGrowth/>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <AnalystView/>
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <CompanyNews/>
                    </TabPanel>
                </div>
            </div>
        </div>
    );
};

export default Market;