import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Drawer from '@material-ui/core/Drawer';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import { signOut } from '../../actions/auth';
import useAuth from "../../hooks/useAuth";
import {logOut} from "../../actions";
import * as constants from "../../constants/CommonConstants";

const drawerWidth = 150;
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  list_root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    '&:focus': {
      outline: 'none'
    }
  },
  title: {
    flexGrow: 1,
  },
  customButtonStyle: {
    '&:focus': {
      outline: 'none'
    }
  },
  paper: {
    top: 'auto'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
  },
  appBar: {
    color: 'black',
    backgroundColor: 'white',
    zIndex: theme.zIndex.drawer + 1,
    marginBottom: '10px'
  },
  sidebar: {
    marginTop: '70px',
    overflowY: 'auto',
    height: '100%'
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  navActive: {
    fontWeight: "bold",
    fontSize: "14px",
    borderBottom: "2px solid black"
  },
  mainNav: {
    marginRight: "10px"
  },
  muiSelected: {
    backgroundColor: "red"
  }
}));


const Layout = props => {
  const classes = useStyles();
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reportedList, setReportedList] = React.useState({});
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [prevDateSelected, setPrevDateSelected] = React.useState({});
  const { showSideDrawer } = props;
  const [openLogOutDialog, setOpenLogOutDialog] = React.useState(false);
  const [logOutDialogEl, setLogOutDialogEl] = React.useState(null);

  const authenticated = useAuth();
  const handleClick = (key) => {
    setReportedList({[key]: !open});
    setOpen(!open);
  };

  const handleDateReportedClick = (dateReported, event) => {
    handleDateSelection(event.target);
    props.onDateReported(dateReported);
  };

  const handleDateSelection = (inputEl) => {
    let parentEl;
    if(prevDateSelected && prevDateSelected.classList){
      parentEl = findAncestor(prevDateSelected, "MuiListItem-root");
      if(parentEl)
        parentEl.classList.remove("Mui-selected");
      else
        console.log(`first parentEl is ${parentEl}`);
    }
    setPrevDateSelected(inputEl);
    parentEl = findAncestor(inputEl, "MuiListItem-root");
    if(parentEl)
      parentEl.classList.add("Mui-selected");
    else
      console.log(`second parentEl is ${parentEl}`);
  };

  const findAncestor = (el, sel) => {
    el = el.parentElement;
    while(el && !el.classList.contains(sel)){
      el = el.parentElement;
    }
    return el;
  };

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (event) => {
    if (event)
      event.preventDefault();
    signOut().then((res) => {
      if(res){
        logOutCleanup();
      }
    }).catch(err => {
      logOutCleanup();
    })
  }

  const logOutCleanup = () => {
    if(typeof props.onLogOutCleanUp === "function")
      props.onLogOutCleanUp();
    else {
      logOut(dispatch);
      localStorage.removeItem(constants.AUTHENTICATED);
      localStorage.removeItem(constants.USER_ID);
      navigate("/income");
    }
  };

  const handleNavigation = (action, event) => {
    if(action === 'logout'){
      setLogOutDialogEl(event.currentTarget);
      setOpenLogOutDialog(true);
    } else
      props.onNavigation(action, event);
  }

  const handleLogOutDialogClose = () => {
    setLogOutDialogEl(null);
    setOpenLogOutDialog(false);
  };

  const handlelogOutCancel = () => {
    setOpenLogOutDialog(false);
  };

  const handleLogOutOk = (event) => {
    handleLogout(event);
  };

  const openMenu = Boolean(anchorEl);
  const id = openMenu ? 'simple-popover' : undefined;

  const createMenuList = () => {
    return state.allDateReported && state.allDateReported.data && (
            Object.entries(state.allDateReported.data).map(([year, quarters]) =>
              <div>
                <ListItem button onClick={() => handleClick(year)}>
                  <ListItemText primary={year} />
                    {reportedList[year] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                {
                  quarters && quarters.length > 0 && (
                    <Collapse in={reportedList[year]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                    {
                      quarters.map((quarter) =>
                            <ListItem button className={classes.nested}>
                              <ListItemText primary={quarter} />
                            </ListItem>
                      )
                    }
                      </List>
                    </Collapse>
                  )
                }
              </div>
          ));
  };

  const slectedItemStyle = {
    backgroundColor: 'red'
  };

  const createCustomMenuList = () => {
    let allDateReported = [];

    if(state.allDateReported && state.allDateReported.data){
      Object.entries(state.allDateReported.data).forEach(entry => {
        let key = entry[0];
        let value = entry[1];
        allDateReported.push({'year': key, 'quarters': value});
      });
      allDateReported.sort(function(obj1, obj2){
        return obj2.year - obj1.year;
      });
    }

    return state.allDateReported && state.allDateReported.data && (
            allDateReported.map(item =>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel_content"
                  id={item.year}
                >
                  <Typography className={classes.heading}>{item.year}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {
                    item.quarters && (
                        <List component="div" disablePadding>
                        {
                          item.quarters.map((quarter, index) =>
                                <ListItem key={index} button id={quarter +"_"+ index} className={classes.nested} onClick={(e) => handleDateReportedClick(quarter, e)}>
                                  <ListItemText primary={quarter}/>
                                </ListItem>
                          )
                        }
                        </List>
                      )
                  }
                </ExpansionPanelDetails>
              </ExpansionPanel>
          ));
  };

  React.useEffect(() => {
  }, []);

  const sideList = side => (
    <div>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Date Reported
          </ListSubheader>
        }
        className={classes.list_root}
      >
        {
          createCustomMenuList()
        }
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton class="menu-icon" className={classes.menuButton} onClick={handleMenuClick} aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Popover
            id={id}
            open={openMenu}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
          {createCustomMenuList()}
        </Popover>
          <Typography variant="h6" className={classes.title}>
            Open Fundamentals
          </Typography>
        <NavLink to="/" className={classes.mainNav}>Dashboard</NavLink>
        <NavLink to="/feedback" className={classes.mainNav} style={{display: state.application.showPrivateLinks == "true"? "block":"none"}}>Feedback</NavLink>
        {/*<NavLink to="/privacy" className={classes.mainNav}>Privacy</NavLink>*/}
          {
          authenticated ? (
            <>
                { state.application.isPublic == "true" && (
                        <NavLink to="/account" className={classes.mainNav}>Account</NavLink>
                    )}
                <NavLink className={classes.mainNav} onClick={(e) => handleNavigation("logout", e)}>Logout</NavLink>
                <Popover
                    open={openLogOutDialog}
                    anchorEl={logOutDialogEl}
                    onClose={handleLogOutDialogClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                >
                  <DialogTitle id="confirmation-dialog-title">Are you sure you want to logout?</DialogTitle>
                  <DialogActions>
                    <Button onClick={handlelogOutCancel} color="primary">
                      NO
                    </Button>
                    <Button onClick={handleLogOutOk} color="primary">
                      YES
                    </Button>
                  </DialogActions>
                </Popover>
            </>
            ): (
              <NavLink to="/login" className={classes.mainNav} style={{display: state.application.showPrivateLinks  == "true"? "block":"none"}}>Login</NavLink>
            )
          }
        </Toolbar>
      </AppBar>
        {
          state && state.allDateReported && state.allDateReported.data
            && showSideDrawer && (
          <Drawer variant='permanent' open={true} class="side-navigation" className={classes.drawer}
            classes={{root: classes.root}}>
            <div className={classes.sidebar}>
              {sideList('left')}
            </div>
          </Drawer>
        )
      }
    </div>
  );
}

export default Layout;