import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { store, history } from '../store';
import Router from './Router';
import '../css/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../scss/app.scss';
import '../css/App.css';


class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <GoogleOAuthProvider clientId="288584267190-djc1n56mn04ie9646ppn8jnu8ood1038.apps.googleusercontent.com">
            <Provider store={store}>
              <BrowserRouter history={history}>
                <Router/>
              </BrowserRouter>
            </Provider>
        </GoogleOAuthProvider>
    );
  }
}

export default App;
