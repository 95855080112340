import React, {useEffect, useState} from 'react';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Bar, Brush} from 'recharts';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { amber, blue, green } from '@material-ui/core/colors';
import {useSelector} from "react-redux";


const AmberCheckbox = withStyles({
    root: {
        color: amber[400],
        '&$checked': {
            color: amber[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const BlueCheckbox = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const DEFAULT_NOT_OF_DATASET = 8;

const SimpleLineChart = (props) => {
    const state = useSelector(state => state);
    const { data } = props;
    const [dataState, setDataState] = React.useState({
        revenue: true,
        netIncome: true,
        operatingExpense: true
    });
    const [startPos, setStartPos] = useState(0);

    const handleChange = (event) => {
        setDataState({ ...dataState, [event.target.name]: event.target.checked });
    };

    useEffect(() => {
        if(data.length > DEFAULT_NOT_OF_DATASET) {
            setStartPos(data.length - DEFAULT_NOT_OF_DATASET)
        }
    }, [data]);

    const getLineChart = () => {
        const keysArr = Object.keys(data[0]).slice(1);
        const lineArr= [];
        keysArr.forEach((item, index)=> {
            let color = "";
            if(item.toLowerCase() === "netincome" && dataState.netIncome)
                color = amber[600];
            else if(item.toLowerCase() === "operatingexpense" && dataState.operatingExpense)
                color = blue[600];
            else if(item.toLowerCase() === "revenue" && dataState.revenue)
                color = green[600];
            lineArr.push(<Line type="monotone" dataKey={item} stroke={color} />)
        })
        return lineArr;
    }

    return (
        <>
            { data && data.length > 0 && (
                <>
                    <FormGroup row class="align-center">
                        <FormControlLabel
                            control={<GreenCheckbox checked={dataState.revenue}
                                               onChange={handleChange}
                                               name="revenue"
                            />}
                            label="Revenue"
                        />
                        <FormControlLabel
                            control={<AmberCheckbox checked={dataState.netIncome} onChange={handleChange} name="netIncome" />}
                            label="Net Income"
                        />
                        <FormControlLabel
                            control={<BlueCheckbox checked={dataState.operatingExpense} onChange={handleChange} name="operatingExpense" />}
                            label="Operating Expense"
                        />
                    </FormGroup>
                    <ResponsiveContainer width="90%" height="100%">
                        <LineChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="period" />
                            <YAxis />
                            <Tooltip contentStyle={{backgroundColor: 'transparent', fontWeight: 'bold', border: 'solid 1px'}} />
                            <Legend />
                            {getLineChart()}
                            <Brush dataKey={Object.keys(data[0])[0]} startIndex={startPos}>
                                <LineChart>
                                    {getLineChart()}
                                </LineChart>
                            </Brush>
                        </LineChart>
                    </ResponsiveContainer>
                </>
                )}
        </>
    );
};

export default SimpleLineChart;