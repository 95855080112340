import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

import Layout from '../Layout';
import { getUser } from '../../actions';
import * as constants from '../../constants/CommonConstants';


const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '30%',
    marginTop: theme.spacing(10),
    '& .MuiFormControl-root': {
          margin: 5
        }
  },
  cardContainer: {
    width: 500
  }
}));

const Account = (props) => {
    const classes = useStyles();
	const dispatch = useDispatch();
	const state = useSelector(state => state);

    useEffect(() => {
        if(state.user && state.user?.profile === undefined)
            getUser(localStorage.getItem(constants.USER_ID), dispatch);
    },[]);

    return (
        <Container component="main" className={classes.root}>
            <Layout showSideDrawer={false} />
            { state.user && state.user.profile && (
                <Card className={classes.cardContainer}>
                    <CardContent>
                        <div>
                            <TextField
                              id="firstName"
                              label="First Name"
                              defaultValue={state.user.profile.first_name}
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                              variant="outlined"
                            />
                        </div>
                        <div>
                            <TextField
                              id="lastName"
                              label="Last Name"
                              defaultValue={state.user.profile.last_name}
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                              variant="outlined"
                            />
                        </div>
                        <div>
                            <TextField
                              id="email"
                              label="Email"
                              defaultValue={state.user.profile.email}
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                              variant="outlined"
                            />
                        </div>
                    </CardContent>
                </Card>
                )}
        </Container>
    );
}

export default Account;