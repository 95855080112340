import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import SimpleDataDisplay from '../common/SimpleDataDisplay';
import { useDispatch, useSelector } from "react-redux";
import { addUpdateBalanceSheet, addUpdateChartIndex, removeSECReport, updateCurrentStatementTab } from '../../actions';
import { dataExist } from '../../constants/CommonUtils';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card : {
    marginLeft: 15,
    display: 'inline-block',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  description: {
    fontSize: 12,
  },
  section: {
    marginTop: 10,
  }
}));


const BalanceSheet = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const [dataSet, setDataSet] = React.useState({});
    const [dataList, setDataList] = React.useState([]);
    const [currentAssetsList, setCurrentAssetsList] = React.useState([]);
    const [otherAssetsList, setOtherAssetsList] = React.useState([]);
    const [currentLiabilitiesList, setCurrentLiabilitiesList] = React.useState([]);
    const [nonCurrentLiabilitiesList, setNonCurrentLiabilitiesList] = React.useState([]);
    const [equityList, setEquityList] = React.useState([]);
    const [returnOnEquity, setReturnOnEquity] = React.useState(0);
    const [returnOnAssets, setReturnOnAssets] = React.useState(0);
    const [currentRatio, setCurrentRatio] = React.useState(0);
    const [receivablesTurnOverRatio, setReceivablesTurnOverRatio] = React.useState(0);
    const [receivablesTurnOverRatioInDays, setReceivablesTurnOverRatioInDays] = React.useState(0);
    const [payablesTurnOverRatio, setPayablesTurnOverRatio] = React.useState(0);
    const [payablesTurnOverRatioInDays, setPayablesTurnOverRatioInDays] = React.useState(0);
    const [liabilitiesToEquityRatio, setLiabilitiesToEquityRatio] = React.useState(0);
    const [activeChartIndex, setActiveChartIndex] = React.useState(0);
    const subTitleText = "Numbers are in millions";


    const restructureDataForDashboard = () => {
      if(state && state.balanceSheetHistory && state.balanceSheetHistory.data){
        setCurrentAssetsList([]);
        setOtherAssetsList([]);
        setCurrentLiabilitiesList([]);
        setNonCurrentLiabilitiesList([]);
        setEquityList([]);
        state.balanceSheetHistory.data.forEach(function(item, index){
          if(item){
            let itemDataSet = {};
            for (let [key, value] of Object.entries(item)) {
              itemDataSet = {
                title: `${key}`,
                data: value,
                id: `${key}` + "_" + index
              }
              let keyName = `${key}`;
              if(keyName === 'cash_and_cash_equivalents' || keyName === 'short_term_investments' 
                || keyName === 'cash_and_short_term_investments' 
                || keyName === 'receivables' || keyName === 'inventories'
                || keyName === 'total_current_assets'){
                setCurrentAssetsList(currentAssetsList => [...currentAssetsList, itemDataSet]);
              }
              else if(keyName === 'net_property_plant_and_equipment' || keyName === 'goodwill_and_intangible_assets'
                || keyName === 'investments' || keyName === 'long_term_investments' 
                || keyName === 'total_non_current_assets' || keyName === 'other_assets'
                || keyName === 'total_assets'){
                setOtherAssetsList(otherAssetsList => [...otherAssetsList, itemDataSet]);
              }
              else if(keyName === 'payables' || keyName === 'short_term_debt' || keyName === 'total_current_liabilities')
                setCurrentLiabilitiesList(currentLiabilitiesList => [...currentLiabilitiesList, itemDataSet]);
              else if(keyName === 'long_term_debt' || keyName === 'total_debt' 
                || keyName === 'total_non_current_liabilities' || keyName === 'other_liabilities'
                || keyName === 'total_liabilities')
                setNonCurrentLiabilitiesList(nonCurrentLiabilitiesList => [...nonCurrentLiabilitiesList, itemDataSet]);
              else if(keyName === 'retained_earnings' || keyName === 'total_shareholders_equity')
                setEquityList(equityList => [...equityList, itemDataSet]);
            }
          }
        });
      }
    };

    React.useEffect(() => {
      if(state.balanceSheetHistory && state.balanceSheetHistory.data){
        restructureDataForDashboard();
      }
    },[state.balanceSheetHistory, state.balanceSheetHistory.data]);

    React.useEffect(() => {
      if(state.balanceSheet && state.balanceSheet.data){
        let netIncome = state.incomeStatement.data.net_income;
        let revenue = state.incomeStatement.data.revenue;
        let costOfRevenue = state.incomeStatement.data.cost_of_revenue;

        let shareHolderEquity = state.balanceSheet.data.total_shareholders_equity;
        let totalEquity = shareHolderEquity + state.balanceSheet.data.retained_earnings;
        let totalAssets = state.balanceSheet.data.total_assets;
        let totalCurrentAssets = state.balanceSheet.data.total_current_assets;
        let totalCurrentLiabilities = state.balanceSheet.data.total_current_liabilities;
        let receivables = state.balanceSheet.data.receivables;
        let payables = state.balanceSheet.data.payables;
        let totalLiabilities = state.balanceSheet.data.total_liabilities;

        if(netIncome > 0 && shareHolderEquity > 0)
            setReturnOnEquity(netIncome/shareHolderEquity);
        if(netIncome > 0 && totalAssets > 0)
            setReturnOnAssets(netIncome/totalAssets);
        if(totalCurrentAssets > 0 && totalCurrentLiabilities > 0)
            setCurrentRatio(totalCurrentAssets/totalCurrentLiabilities);

        if(revenue > 0 && receivables > 0) {
            const receivablesTurnOverRatioCalc = revenue / receivables;
            setReceivablesTurnOverRatio(revenue / receivables);
            setReceivablesTurnOverRatioInDays(365 / receivablesTurnOverRatioCalc);
        }

        if(costOfRevenue > 0 && payables > 0) {
            const payablesTurnOverRatioCalc = costOfRevenue/payables;
            setPayablesTurnOverRatio(costOfRevenue / payables);
            setPayablesTurnOverRatioInDays(365 / payablesTurnOverRatioCalc);
        }
        if(totalLiabilities > 0 && shareHolderEquity > 0)
            setLiabilitiesToEquityRatio(totalLiabilities/shareHolderEquity);
      }
    },[state.balanceSheet]);

    const handleItemChartClick = (selectedIndex) => {
      setActiveChartIndex(selectedIndex);
      dispatch(addUpdateChartIndex(selectedIndex));

      let currentBalanceStatement = state.balanceSheet.data;
      state.balanceSheetHistory.data.forEach(function(item, index){
          if(item){
            let itemDataSet = {};
            for (let [key, value] of Object.entries(item)) {
              let keyName = `${key}`;
              if(value && value[selectedIndex]){
                currentBalanceStatement[keyName] = value[selectedIndex].value;
              }
            }
          }
        });
      dispatch(addUpdateBalanceSheet(currentBalanceStatement));
      dispatch(updateCurrentStatementTab("balanceSheet"));
      dispatch(removeSECReport());
    };

    React.useEffect(() => {
      if(state.chart && state.chart.index > -1){
        setActiveChartIndex(state.chart.index);
      }
    },[state.chart]);

    return (
          <div className="container__wrap">
              {
                state && state.balanceSheet && state.balanceSheet.data && (
                  <div class="financial_statement_title">
                    <h6>
                      Balance Sheet: Balance Sheet is made up of three categories: the assets, the equity and the liabilities.
                      Assets is what company owns and can be financed with the company's money which is equity or 
                      someone else money which is called a liabilities.
                      <br/>
                      ({subTitleText.toUpperCase()})
                      <br/>
                      Currency Reported: {state.currencyReported.value}
                    </h6>
                  </div>
                )
              }
            <div className="dashboard">
              <div>
              {
                currentAssetsList && currentAssetsList.length > 0 && (
                  currentAssetsList.map(function(item, index){
                    if(dataExist(item.data)){
                      return <SimpleDataDisplay key={item.id} id={item.id} data={item.data}
                              title={item.title} dir={state.rtl.direction} 
                              help={item.title}
                              onItemChartSelection={handleItemChartClick}
                              activeChartIndex={activeChartIndex}
                              />;
                      }
                  })
                )
              }
              </div>
              <div>
              {
                returnOnEquity > 0 && (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h5" component="h2" className={classes.title} color="textSecondary" gutterBottom>
                        Return On Equity: {(returnOnEquity * 100).toFixed(2)}% 
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.description}>
                        Company has made a return of ${(returnOnEquity * 100).toFixed(2)} for every $100 the company has retained
                        from previous earnings or initial investments. 
                        Higher the ROE the better. Good companies have a consistent ROE of 8% or better.
                      </Typography>
                    </CardContent>
                  </Card>
                )
              }
              {
                receivablesTurnOverRatio > 0 && (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h5" component="h2" className={classes.title} color="textSecondary" gutterBottom>
                        Account Receivables Turnover Ratio (Number of days): {receivablesTurnOverRatioInDays.toFixed(2)}
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.description}>
                        Company typically takes {receivablesTurnOverRatioInDays.toFixed(2)} days to receive payment from 
                        their customer once they make a sale.
                      </Typography>
                    </CardContent>
                  </Card>
                )
              }
              </div>
              <div className={classes.section}>
              {
                otherAssetsList && otherAssetsList.length > 0 && (
                  otherAssetsList.map(function(item, index){
                    if(dataExist(item.data)){
                      return <SimpleDataDisplay key={item.id} id={item.id} data={item.data}
                              title={item.title} dir={state.rtl.direction} 
                              help={item.title} 
                              onItemChartSelection={handleItemChartClick}
                              activeChartIndex={activeChartIndex}
                              />;
                      }
                  })
                )
              }
              </div>
              {
                  returnOnAssets && Math.floor(returnOnAssets) > 0 && (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h5" component="h2" className={classes.title} color="textSecondary" gutterBottom>
                        Return On Assets: {returnOnAssets.toFixed(2) * 100}% 
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.description}>
                        ROA determines the debt of the company. Lower the ROA higher the debt and vice versa.
                      </Typography>
                    </CardContent>
                  </Card>
                )
              }
              <div className={classes.section}>
              {
                currentLiabilitiesList && currentLiabilitiesList.length > 0 && (
                  currentLiabilitiesList.map(function(item, index){
                    if(dataExist(item.data)){
                      return <SimpleDataDisplay key={item.id} id={item.id} data={item.data}
                              title={item.title} dir={state.rtl.direction} 
                              help={item.title} 
                              onItemChartSelection={handleItemChartClick}
                              activeChartIndex={activeChartIndex}                            
                              />;
                    }
                  })
                )
              }  
              </div>
              <div>
              {
                currentRatio > 0 && (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h5" component="h2" className={classes.title} color="textSecondary" gutterBottom>
                        Current Ratio: {currentRatio.toFixed(2)} 
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.description}>
                        Current Ratio determines cash inflow versus outflow in next 12 months.
                        Higher than 1 ratio means the companies has more cash coming in vs out.
                      </Typography>
                    </CardContent>
                  </Card>
                )
              }
              {
                payablesTurnOverRatio > 1 && (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h5" component="h2" className={classes.title} color="textSecondary" gutterBottom>
                        Account Payables Turnover Ratio (Number of days): {payablesTurnOverRatioInDays.toFixed(2)}
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.description}>
                        Company takes {payablesTurnOverRatioInDays.toFixed(2)} days to make payments.
                      </Typography>
                    </CardContent>
                  </Card>
                )
              }
              </div>
              <div className={classes.section}>
              {
                nonCurrentLiabilitiesList && nonCurrentLiabilitiesList.length > 0 && (
                  nonCurrentLiabilitiesList.map(function(item, index){
                    if(dataExist(item.data)){
                      return <SimpleDataDisplay key={item.id} id={item.id} data={item.data}
                              title={item.title} dir={state.rtl.direction} 
                              help={item.title} 
                              onItemChartSelection={handleItemChartClick}
                              activeChartIndex={activeChartIndex}                            
                              />;
                    }
                  })
                )
              }  
              </div>
              <div className={classes.section}>
              {
                equityList && equityList.length > 0 && (
                  equityList.map(function(item, index){
                    if(dataExist(item.data)){  
                      return <SimpleDataDisplay key={item.id} id={item.id} data={item.data}
                              title={item.title} dir={state.rtl.direction} 
                              help={item.title} 
                              onItemChartSelection={handleItemChartClick}
                              activeChartIndex={activeChartIndex}                            
                              />;
                      }
                  })
                )
              }
              </div>
              <div>
              {
                liabilitiesToEquityRatio > 0 && (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h5" component="h2" className={classes.title} color="textSecondary" gutterBottom>
                        Liabilities To Equity Ratio: {(liabilitiesToEquityRatio*100).toFixed(2)}% 
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.description}>
                        For every $100 in equity, the company will make payment of ${(liabilitiesToEquityRatio*100).toFixed(2)} at some point in the future.
                      </Typography>
                    </CardContent>
                  </Card>
                )
              }  
              </div>
            </div>
          </div>
    );
}

export default BalanceSheet;
