export const AUTHENTICATED = 'authenticated';
export const SYMBOL_SELECTED = 'symbolSelected';
export const REPORT_TYPE = 'reportType';
export const USER_ID = "userId";
export const QUARTER = "quarter";
export const ANNUAL = "annual";
export const PAST_SUGGESTIONS = 'pastSuggestions';
export const TOTAL_NO_PAST_SUGGESTIONS = 8;
export const REVENUE_SEGEMNT = {
    PRODUCT: "product",
    GEO: "geo"
}
export const INTRADAY_POLLING_TIME = 5000;
export const EXTENDED_HOURS_POLLING_TIME = 5000;
