import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import SimpleDataDisplay from '../common/SimpleDataDisplay';
import { useDispatch, useSelector } from "react-redux";
import { dataExist } from '../../constants/CommonUtils';
import { addUpdateCashFlow, addUpdateChartIndex, removeSECReport, updateCurrentStatementTab } from '../../actions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card : {
    marginLeft: 15,
    display: 'inline-block',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  description: {
    fontSize: 12,
  },
  section: {
    marginTop: 10,
  }
}));


const CashFlow = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const [dataSet, setDataSet] = React.useState({});
    const [dataList, setDataList] = React.useState([]);
    const [operatingActivitiesList, setOperatingActivitiesList] = React.useState([]);
    const [investingActivitiesList, setInvestingActivitiesList] = React.useState([]);
    const [financingActivitiesList, setFinancingActivitiesList] = React.useState([]);
    const [otherCashFlowList, setOtherCashFlowList] = React.useState([]);
    const [freeCashFlowToSalesRatio, setFreeCashFlowToSalesRatio] = React.useState(0);
    const [activeChartIndex, setActiveChartIndex] = React.useState(0);
    const subTitleText = "Numbers are in millions";

    const restructureDataForDashboard = () => {
      if(state && state.cashFlowHistory && state.cashFlowHistory.data){
        setOperatingActivitiesList([]);
        setInvestingActivitiesList([]);
        setFinancingActivitiesList([]);
        setOtherCashFlowList([]);
        state.cashFlowHistory.data.forEach(function(item, index){
          if(item){
            let itemDataSet = {};
            for (let [key, value] of Object.entries(item)) {
              itemDataSet = {
                title: `${key}`,
                data: value,
                id: `${key}`
              }
              let keyName = `${key}`;
              if(keyName === 'depreciation_and_amortization' 
                || keyName === 'stock_based_compensation' || keyName === 'operating_cash_flow')
                setOperatingActivitiesList(operatingActivitiesList => [...operatingActivitiesList, itemDataSet]);
              else if(keyName === 'capital_expenditure' || keyName === 'investments' 
                || keyName === 'investing_cash_flow')
                setInvestingActivitiesList(investingActivitiesList => [...investingActivitiesList, itemDataSet]);
              else if(keyName === 'issuance_or_repayment_of_debt' 
                || keyName === 'issuance_or_buybacks_of_shares' || keyName === 'dividend_payments')
                setFinancingActivitiesList(financingActivitiesList => [...financingActivitiesList, itemDataSet]);
              else if(keyName === 'financing_cash_flow' || keyName === 'free_cash_flow')
                setOtherCashFlowList(otherCashFlowList => [...otherCashFlowList, itemDataSet]);
            }
          }
        });
      }
    };

    React.useEffect(() => {
      if(state.cashFlowHistory && state.cashFlowHistory.data){
        restructureDataForDashboard();
      }
    },[state.cashFlowHistory, state.cashFlowHistory.data]);

    React.useEffect(() => {
      if(state.cashFlow && state.cashFlow.data){
        restructureDataForDashboard();

        let revenue = state.incomeStatement.data.revenue;
        let operatingCashFlow = state.cashFlow.data.operating_cash_flow;
        let capEx = state.cashFlow.data.capital_expenditure;

        if(revenue > 0)
            setFreeCashFlowToSalesRatio((operatingCashFlow + capEx)/revenue);
      }
    },[state.cashFlow]);
    
    const handleItemChartClick = (selectedIndex) => {
      setActiveChartIndex(selectedIndex);
      dispatch(addUpdateChartIndex(selectedIndex));

      let currentCashFlowStatement = state.cashFlow.data;
      state.cashFlowHistory.data.forEach(function(item, index){
          if(item){
            let itemDataSet = {};
            for (let [key, value] of Object.entries(item)) {
              let keyName = `${key}`;
              if(value && value[selectedIndex]){
                currentCashFlowStatement[keyName] = value[selectedIndex].value;
              }
            }
          }
        });
      dispatch(addUpdateCashFlow(currentCashFlowStatement));
      dispatch(updateCurrentStatementTab("cashFlow"));
      dispatch(removeSECReport());
    };

    React.useEffect(() => {
      if(state.chart && state.chart.index > -1){
        setActiveChartIndex(state.chart.index);
      }
    },[state.chart]);

    return (
          <div className="container__wrap">
              {
                state && state.cashFlow && state.cashFlow.data && (
                  <div class="financial_statement_title">
                    <h6>
                      Cash Flow: Cash Flow is the connection between the income statement and the balance sheet
                      and which gives a synergized vantage point of the company's financial situation.
                      Cash Flow is equivalent to individuals checking account.
                      <br/>
                      ({subTitleText.toUpperCase()})
                      <br/>
                      Currency Reported: {state.currencyReported.value}
                    </h6>
                  </div>
                )
              }
            <div className="dashboard">
              <div>
              {
                operatingActivitiesList && operatingActivitiesList.length > 0 && (
                  operatingActivitiesList.map(function(item, index) {
                      if(dataExist(item.data)){
                        return <SimpleDataDisplay key={item.id} id={item.id} data={item.data} 
                          title={item.title} dir={state.rtl.direction} 
                          help={item.title} 
                          onItemChartSelection={handleItemChartClick}
                          activeChartIndex={activeChartIndex}                            
                          />;
                      }
                    }
                  )
                )
              }
              </div>
              <div>
              {
                freeCashFlowToSalesRatio > 0 && (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h5" component="h2" className={classes.title} color="textSecondary" gutterBottom>
                        Free CashFlow To Sales Ratio: {(freeCashFlowToSalesRatio * 100).toFixed(2)}% 
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.description}>
                        For every $100, ${(freeCashFlowToSalesRatio * 100).toFixed(2)} will be available as cash for the shareholders.
                      </Typography>
                    </CardContent>
                  </Card>
                )
              }
              </div>
              <div className={classes.section}>
              {
                investingActivitiesList && investingActivitiesList.length > 0 && (
                  investingActivitiesList.map(function(item, index){
                    if(dataExist(item.data)){
                      return <SimpleDataDisplay key={item.id} id={item.id} data={item.data} 
                              title={item.title} dir={state.rtl.direction} 
                              help={item.title} 
                              onItemChartSelection={handleItemChartClick}
                              activeChartIndex={activeChartIndex}                            
                              />;
                      }
                  })
                )
              }
              </div>
              <div className={classes.section}>
              {
                financingActivitiesList && financingActivitiesList.length > 0 && (
                  financingActivitiesList.map(function(item, index){
                    if(dataExist(item.data)){
                      return <SimpleDataDisplay key={item.id} id={item.id} data={item.data} 
                              title={item.title} dir={state.rtl.direction} 
                              help={item.title} 
                              onItemChartSelection={handleItemChartClick}
                              activeChartIndex={activeChartIndex}                            
                              />;
                      }
                  })
                )
              }
              </div>
              <div className={classes.section}>
              {
                otherCashFlowList && otherCashFlowList.length > 0 && (
                  otherCashFlowList.map(function(item, index){
                    if(dataExist(item.data)){
                      return <SimpleDataDisplay key={item.id} id={item.id} data={item.data} 
                              title={item.title} dir={state.rtl.direction} 
                              help={item.title}
                              onItemChartSelection={handleItemChartClick}
                              activeChartIndex={activeChartIndex}
                              />;
                      }
                  })
                )
              }
              </div>
            </div>
          </div>
    );
}

export default CashFlow;
