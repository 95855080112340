import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, NavLink, Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import SearchBar from '../SearchBar';
import Layout from '../Layout';
import { isAuthenticated } from '../../actions/auth';
import {
	addAllFinancialStatementHistory, addCompanyEarningsCalendar,
	addUserAuthenticationStatus,
	getEarningsCalendar, getUser,
	onDateSelection
} from '../../actions';
import { addAllFinancialStatement, addAllDateReported,
		addCompanyProfile, removeSECReport, logOut } from '../../actions';
import { get } from '../../actions/apiClient';
import * as constants from '../../constants/CommonConstants';
import {symbol} from "../../reducers/company";

const useStyles = makeStyles((theme) => ({
	bodyContainer: {
	}
}));

const Dashboard = props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { location } = useLocation();
	const state = useSelector(state => state);
	const [currentNavigation, setCurrentNavigation] = React.useState('');
	const [redirect, setRedirect] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [initFinancialStatement, setInitFinancialStatement] = React.useState(false);
	const [suggestionSelectedValue, setSuggestionSelectedValue] = React.useState(false);
	const [value, setSuggestionValue] = React.useState('');
	const [authenticated, setAuthenticated] = React.useState(false);
	const [quarterlyReport, setQuarterlyReport] = React.useState(true);

	const getAllFinancialStatements = (symbol, latest, dateReported, period) => {
		setLoading(true);
		var endpoint = '/api/corefinancials/get_financial_statements/'+ symbol + '/'+ latest + '/' + dateReported + '/' + period;
		get(endpoint, "GET").then((res) => {
		  //if no date reported
		  if(!state.allDateReported.data)
		  	getAllDateReported(symbol, period);
		  getCompanyProfile(symbol);
		  getEarningsCalendarData(symbol);
		  addAllFinancialStatement(res, dispatch);
		  setInitFinancialStatement(true);
		  setSuggestionSelectedValue(true);
		  clearSuggestionInputValue();
		  setLoading(false);
          defaultNavigation();
		  getAllFinancialStatementHistory(symbol);
		})
		.catch(err => {
			setLoading(false);
			handleError(err);
		});
	}

	const getAllFinancialStatementHistory = (symbol) => {
		var endpoint = '/api/corefinancials/get_all_financial_statement_history/'+ symbol + '/'+ true + '/' + "annual";
		get(endpoint, "GET").then((res) => {
			addAllFinancialStatementHistory(res, dispatch);
		}).catch(err => {
				handleError(err);
			});
	}

	const getAllDateReported = (symbol, period) => {
		var endpoint = '/api/corefinancials/get_all_date_reported/'+ symbol + '/' + period;
		get(endpoint, "GET").then((res) => {
		  dispatch(addAllDateReported(res));
		})
		.catch(err => {
			handleError(err);
		});
	};
	const defaultNavigation = () => {
	    navigate("/");
	};
	const getCompanyProfile = (symbol) => {
		var endpoint = '/api/corefinancials/get_company_profile/' +symbol;
		get(endpoint, "GET").then((res) => {
		  dispatch(addCompanyProfile(res));
		})
		.catch(err => {
		  console.log(err);
		});
	};

	const getEarningsCalendarData = (symbol) => {
		getEarningsCalendar(symbol).then(res => {
			dispatch(addCompanyEarningsCalendar(res));
		}).catch(err => {
			console.log(err);
		});
	};

	const onDateReported = (dateReported) => {
		let period = quarterlyReport;
		const symbol = state.symbol.data.symbol;
		dispatch(removeSECReport());
		dispatch(onDateSelection(true));
		getAllFinancialStatements(symbol, 'none', dateReported, period);
	};

    const onNavigation = (navLink, e) => {
        e.preventDefault();
        setCurrentNavigation(navLink);
        if(navLink === 'dashboard'){
            if(state.incomeStatement.data)
                defaultNavigation();
            else
                navigate('/')
        }else
            navigate(navLink);
    };
	const handleError = (err) => {
		if(err.message && err.message === "expired_token"){
			onLogOutCleanUp();
			logOut(dispatch);
		}
		console.log(err);
	};
    const onLogOutCleanUp = () => {
        setLoading(false);
		logOut(dispatch);
		localStorage.removeItem(constants.AUTHENTICATED);
		localStorage.removeItem(constants.USER_ID);
		defaultNavigation();
    };

	const onToggleReportType = (quarterlyReport) => {
		setQuarterlyReport(quarterlyReport);
	};

    const clearSuggestionInputValue = () => {
        setSuggestionValue("");
    };
    const onChange = (event, { newValue, method }) => {
        setSuggestionValue(newValue);
    };
    const onBlur = (event, { highlightedSuggestion }) => {
        var value = highlightedSuggestion;
    };

    const onLoad = (event) => {
        const hasAuthenticated = localStorage.getItem(constants.AUTHENTICATED);
		defaultNavigation();
        if(hasAuthenticated){
			addUserAuthenticationStatus(hasAuthenticated, dispatch);
            isAuthenticated().then((res) => {
                if(res!==undefined){
					addUserAuthenticationStatus(res, dispatch);
                }
				if(res) {
					if(state.user?.profile === undefined)
						getUser(localStorage.getItem(constants.USER_ID), dispatch);
				}
            });
        }else
            logOut(dispatch);
    };

    React.useEffect(() => {
		onLoad();
    },[state.user && state.user.userId]);

	return (
		<div>
		    { state.application.isPublic == "true" || state.user.isAuthenticated ? (
		    <>
		  	<Layout onDateReported={onDateReported} onLogOutCleanUp={onLogOutCleanUp} onNavigation={onNavigation} showSideDrawer={true} />
		  	<div>
		  	    <SearchBar
		  	        onNavigation={onNavigation}
		  	        getAllFinancialStatements={getAllFinancialStatements}
		  	        getAllDateReported={getAllDateReported}
		  	        value={value}
		  	        onChange={onChange}
		  	        setSuggestionSelectedValue={setSuggestionSelectedValue}
		  	        onLogOutCleanUp={onLogOutCleanUp}
					loading={loading}
					onToggleReportType={onToggleReportType}
		  	        />
			</div>
			</>): (<Navigate to="login" state={{ from: location }} replace />)}
		</div>
	);
};

export default Dashboard;