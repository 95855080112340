import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from '@material-ui/icons/Delete';
import { grey } from '@material-ui/core/colors';
import { Dialog, DialogContent, DialogActions, DialogContentText } from "@material-ui/core";
import Button from '@material-ui/core/Button';

import {addSECReport, deleteUserSelection, updateUserSECSelection} from "../../actions";



const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
    },
    bodyContainer: {
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    dividerMargin: {
        marginTop: '20px'
    },
    subNavAlign: {
        marginLeft: "25px",
    },
    deleteIconHoverStyle: {
        "&:hover": {
            color: "red"
        }
    },
    itemSelectedStyle: {
        "&:hover": {
            backgroundColor: grey[200],
            cursor: "pointer",
        }
    },
    confirmDialogContentStyle: {
        fontWeight: "bold"
    }
}));

const MySelection = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const state = useSelector(state => state);
    const [showDeleteIcon, setShowDeleteIcon] = useState(false);
    const [currentItemHover,setCurrentItemHover] = useState("");
    const [open, setOpen] = React.useState(false);
    const [currentDeleteId, setCurrentDeleteId] = useState("");
    const [deleteActionPerformed, setDeleteActionPerformed] = useState(false);

    const { onSaveSecSelection } = props;

    const handleMouseOver = (id) => {
        setShowDeleteIcon(true);
        setCurrentItemHover(id);
    };

    const handleMouseOut = () => {
        setShowDeleteIcon(false);
        setCurrentItemHover("");
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const confirmDelete = (id) => {
        setOpen(true);
        setCurrentDeleteId(id);
    };

    const handleDelete = () => {
        dispatch(deleteUserSelection(currentDeleteId));
        setOpen(false);
        setDeleteActionPerformed(true);
        setCurrentDeleteId("");
    };

    useEffect( () => {
        if(deleteActionPerformed) {
            const updatedUserSelections = state.secReport.user_selection.map(item => {
                return item.selection;
            });
            onSaveSecSelection(updatedUserSelections, true);
            setDeleteActionPerformed(false);
        }
    }, [deleteActionPerformed]);

    return (
        <>
            <Typography gutterBottom variant="subtitle1">
                {
                    state?.secReport?.user_selection.length > 0 ?
                        state?.secReport?.user_selection.map((item, index) => {
                        return (<p key={index} onMouseOver={() => handleMouseOver("item-"+index)}
                                   onMouseOut={handleMouseOut} className={classes.itemSelectedStyle}>
                            {item.selection}
                            <span style={{ display: currentItemHover === "item-"+index ? "inline-block" : "none" }}
                                  onClick={ () => confirmDelete(item.id) }>
                                <DeleteIcon className={classes.deleteIconHoverStyle}/>
                            </span>
                        </p>);
                    }): <div>You do not have any selections for this company's report. You can highlight from
                            quarterly or annual report for future reference.</div>
                }
            </Typography>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText classes={{root: classes.confirmDialogContentStyle}} id="alert-dialog-description">
                        Are you sure you want to delete this selection?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        No
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MySelection;