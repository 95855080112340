import React from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';

import FacebookLogin from 'react-facebook-login';
import { useGoogleLogin } from '@react-oauth/google';

import { signIn, socialLogin, getGoogleUser } from '../../actions/auth';
import {addUser, addUserAuthenticationStatus, addUserId, getUser, getUserFeedback} from '../../actions';
import * as constants from '../../constants/CommonConstants';

import Layout from "../Layout";


function Copyright() {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
          Open Fundamental
        {' ' +new Date().getFullYear()}
      </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginForm = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const [inputs, setInputs] = React.useState({});
  const [loginSuccess, setLoginSuccess] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = (event) => {
    if (event)
      event.preventDefault();
    signIn(inputs.username, inputs.password).then((res) =>{
      if(res){
        setAuthenticationStatus(res);
      }
    });
  }

  const handleInputChange = (event) => {
    event.persist();
    setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
  }

  React.useEffect(() => {
    let isPublic = process.env.REACT_APP_PUBLIC;
    if(state.userAuthenticationStatus && state.userAuthenticationStatus.data){
      let authenticationStatus = state.userAuthenticationStatus.data;
    }
  },[state.userAuthenticationStatus]);

  const fbResponse = (response) => {
    onLoginResponse(response.accessToken, "facebook");
    manageUser(response);
  }
  const googleResponse = (response) => {
    if (response.access_token) {
      onLoginResponse(response.access_token, "google-oauth2");
      getGoogleUser(response.access_token).then(response => {
        manageUser(response)
      }).catch(err => {
        console.log(err);
      })
    } else if (response.error)
      console.log(response.error);
  }

  const manageUser = (response) => {
    dispatch(addUserId(response.email));
    getUserData(response.email);
    localStorage.setItem(constants.USER_ID,response.email);
  };

  const googleLogin = useGoogleLogin({
      onSuccess: googleResponse,
      onError: googleResponse
  });

  const onLoginResponse = (accessToken, loginType) => {
    socialLogin(accessToken, loginType).then((res) =>{
      if(res){
        setAuthenticationStatus(res);
        navigate(location.state?.from || "income");
      }
    });
  };

  const setAuthenticationStatus = (res) => {
    addUserAuthenticationStatus(res, dispatch);
    localStorage.setItem(constants.AUTHENTICATED,true);
  };

  const getUserData = (userId) => {
    getUser(userId, dispatch);
    getUserFeedback(userId, dispatch);
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <Layout showSideDrawer={false} />
      <CssBaseline />
      {
        state.user.isAuthenticated && (
          <Navigate to="/" />
        )
      }

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        { state.application.isPublic == "true" ? (
        <>
            <FacebookLogin
                      icon={<FacebookIcon/>}
                      textButton="LOGIN WITH FACEBOOK"
                      appId= "683384199993003"
                      fields="name,email"
                      size="medium"
                      cssClass="login-button"
                      callback={fbResponse}
                    />
            <br/>
            <Button startIcon={<GoogleIcon/>} color="primary" variant="outlined" size="medium" onClick={() => googleLogin()}>
                LogIn With Google
            </Button>
        </>
        ):(
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoFocus
            onChange={handleInputChange}
            value={inputs.username}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleInputChange}
            value={inputs.password}
          />
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default LoginForm;