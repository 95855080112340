import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Rating from '@material-ui/lab/Rating';
import SimpleSpinner from '../common/SimpleSpinner';
import LensIcon from '@material-ui/icons/Lens';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {getUserFeedback, postFeedback} from '../../actions';
import Layout from '../Layout';
import * as constants from "../../constants/CommonConstants";


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10)
  },
  selected: {
    backgroundColor: "#f2f4f7",
    padding: "4px"
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    textAlign: 'center',
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formContainer: {
    textAlign: 'left',
    display: 'inline-block'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage : {
    color: "red"
  }
}));

const StyledRating = withStyles({
  iconEmpty:{
    color: '#D3D3D3'
  },
  iconFilled: {
    color: '#ff6d75',
  },
  iconHover: {
    color: '#ff3d47',
  },
})(Rating);

const StyledBox = withStyles({
  ml:"{2}", 
  mt:"4px",
  position:"absolute",
  fontSize: "{16}"
})(Box);

const FeedbackForm = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(state => state);
  const [ratings, setRatings] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [ratingsHover, setRatingsHover] = React.useState({});
  const [feedbackResponse, setFeedbackResponse] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(false);

  const userId = state.user?.profile?.email || localStorage.getItem(constants.USER_ID);

  const handleSubmit = (event) => {
    if (event)
      event.preventDefault();

    if(validateForm()){
      setLoading(true);
      setBtnDisabled(true);
      let feedback = ratings;
      feedback.email = userId;
      postFeedback(feedback).then((res) =>{
        setLoading(false);
        if(res){
          setFeedbackResponse(true);
          getUserFeedback(userId, dispatch);
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }

  const validateForm = () => {
    let ratingNames = ["dataQualityUS", "dataQualityForeign", "recommendToFriend", "websiteDesign", "financialDataNavigation"];
    let validated = true;
    for(let ratingIndex in ratingNames){
      if(ratings){
        if(!ratings[ratingNames[ratingIndex]]){
          displayRequiredField(ratingNames[ratingIndex]);
          validated = false;
        }
      }else{
        displayRequiredField(ratingNames[ratingIndex]);
        validated = false;
      }
    }
    if(!userId)
      validated = false;

    return validated;
  }

  useEffect(() => {
    if(state.user?.feedback === undefined)
      getUserFeedback(userId, dispatch);
  }, []);

  const displayRequiredField = (ratingName) => {
    document.getElementById(ratingName + "-required").style.display = "block";
    document.getElementById(ratingName + "-required").style.color = "red";
  }

  const hideError = (name) => {
    document.getElementById(name + "-required").style.display = 'none';
  }

  return (
    <Container component="main" className={classes.root}>
    <Layout showSideDrawer={false} />
      {
        !feedbackResponse && state.user.feedback && (
              <Typography class="align-center" variant="h3" gutterBottom>
                Thank you, you have already provided your feedback.
              </Typography>
          )
      }
      {
        feedbackResponse && (
          <Typography class="align-center" variant="h3" gutterBottom>
            Thank you for your feedback!!!
          </Typography>
        )
      }
      { !feedbackResponse && !state.user.feedback && (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <div className={classes.formContainer}>
            <div>
              <Typography variant="h3" gutterBottom>
                  Please let us know what you think!
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                How would you rate the quality of financial data for 
                US companies?
              </Typography>
              <StyledRating max={10}
                onChange={(event, newValue) => {
                  setRatings({...ratings, ["dataQualityUS"]: newValue});
                  hideError("dataQualityUS");
                }}
                onChangeActive={(event, newHover) => {
                  setRatingsHover({...ratingsHover, ["dataQualityUSHover"]: newHover});
                }}
                icon={<LensIcon fontSize="inherit" />} />
                {ratings["dataQualityUS"] !== null && <Box component="span" ml={2} position="absolute" fontSize={16}>{ratingsHover["dataQualityUSHover"] && ratingsHover["dataQualityUSHover"] !== -1 ? ratingsHover["dataQualityUSHover"] : ratings["dataQualityUS"]}</Box>}
                <div id="dataQualityUS-required" style={{ display:'none' }}>this field is required</div>
            </div>
            <SimpleSpinner loading={loading} />
            <div>
              <Typography variant="subtitle1" gutterBottom>
                How would you rate the quality of financial data for 
                Non-US companies?
              </Typography>
              <StyledRating max={10} 
                onChange={(event, newValue) => {
                  setRatings({...ratings, ["dataQualityForeign"]: newValue});
                  hideError("dataQualityForeign");
                }}
                onChangeActive={(event, newHover) => {
                  setRatingsHover({...ratingsHover, ["dataQualityForeignHover"]: newHover});
                }} 
                icon={<LensIcon fontSize="inherit" />} />
                {ratings["dataQualityForeign"] !== null && <Box component="span" ml={2} position="absolute" fontSize={16}>{ratingsHover["dataQualityForeignHover"] && ratingsHover["dataQualityForeignHover"] !== -1 ? ratingsHover["dataQualityForeignHover"] : ratings["dataQualityForeign"]}</Box>}
                <div id="dataQualityForeign-required" style={{ display:'none' }}>this field is required</div>
            </div>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                How likely are you to recommend OpenFundamental to a friend or colleague?
              </Typography>
              <StyledRating max={10} 
                onChange={(event, newValue) => {
                  setRatings({...ratings, ["recommendToFriend"]: newValue});
                  hideError("recommendToFriend");
                }}
                onChangeActive={(event, newHover) => {
                  setRatingsHover({...ratingsHover, ["recommendHover"]: newHover});
                }} 
                icon={<LensIcon fontSize="inherit" />} />
                {ratings["recommendToFriend"] !== null && <Box component="span" ml={2} position="absolute" fontSize={16}>{ratingsHover["recommendHover"] && ratingsHover["recommendHover"] !== -1 ? ratingsHover["recommendHover"] : ratings["recommendToFriend"]}</Box>}
                <div id="recommendToFriend-required" style={{ display:'none' }}>this field is required</div>
            </div>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                How would you rate the design of our website?
              </Typography>
              <StyledRating max={10} 
                onChange={(event, newValue) => {
                  setRatings({...ratings, ["websiteDesign"]: newValue});
                  hideError("websiteDesign");
                }}
                onChangeActive={(event, newHover) => {
                  setRatingsHover({...ratingsHover, ["websiteDesignHover"]: newHover});
                }} 
                icon={<LensIcon fontSize="inherit" />} />
                {ratings["websiteDesign"] !== null && <Box component="span" ml={2} position="absolute" fontSize={16}>{ratingsHover["websiteDesignHover"] && ratingsHover["websiteDesignHover"] !== -1 ? ratingsHover["websiteDesignHover"] : ratings["websiteDesign"]}</Box>}
                <div id="websiteDesign-required" style={{ display:'none' }}>this field is required</div>
            </div>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                How easy was it to navigate and understand financial data on our website?
              </Typography>
              <StyledRating max={10} 
                onChange={(event, newValue) => {
                  setRatings({...ratings, ["financialDataNavigation"]: newValue});
                  hideError("financialDataNavigation");
                }}
                onChangeActive={(event, newHover) => {
                  setRatingsHover({...ratingsHover, ["financialDataNavigationHover"]: newHover});
                }} 
                icon={<LensIcon fontSize="inherit" />} />
                {ratings["financialDataNavigation"] !== null && <Box component="span" ml={2} position="absolute" fontSize={16}>{ratingsHover["financialDataNavigationHover"] && ratingsHover["financialDataNavigationHover"] !== -1 ? ratingsHover["financialDataNavigationHover"] : ratings["financialDataNavigation"]}</Box>}
                <div id="financialDataNavigation-required" style={{ display:'none' }}>this field is required</div>
            </div>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Do you have any other comments, questions or concerns?
              </Typography>
              <div>
                <TextareaAutosize
                    rowsMin={4}
                    style={{ width: "100%" }}
                    value={ratings.anyCommentsQuestionsConcerns}
                    onChange={(event) => {
                      setRatings({...ratings, ["anyCommentsQuestionsConcerns"]: event.target.value});
                    }}
                    placeholder="Any comments, questions or concerns..."
                  />
               </div>
            </div>
            <div>
              { !userId && (
              <div className={classes.errorMessage}>
                Please login and try again.
              </div>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={btnDisabled}
                className={classes.submit}>
                <Typography variant="button" display="block" gutterBottom>
                  Submit
                </Typography>
              </Button>
            </div>
          </div>
        </form>)
      }
    </Container>
  );
}

export default FeedbackForm;