import {
    ADD_SYMBOL,
    ANALYST_ESTIMATES,
    ANALYST_STOCK_RECOMMENDATION, COMPANY_NEWS,
    COMPANY_RATINGS, COMPANY_SUMMARY,
    GET_SYMBOL, MARKET_HOURS, PRE_POST_MARKET_TRADE
} from '../constants/actionTypes';


export const symbol = (state = {}, action) => {
    switch(action.type){
        case ADD_SYMBOL:
            return {
                ...state,
                data: action.payload
            }
        case GET_SYMBOL:
            return state;
        default:
            return state;
    }
};

export const company_info = (state = null, action) => {
    switch(action.type){
        case ANALYST_STOCK_RECOMMENDATION:
            return {
                ...state,
                analyst_stock_recommendation: action.payload
            }
        case ANALYST_ESTIMATES:
            return {
                ...state,
                analyst_estimates: action.payload
            }
        case COMPANY_RATINGS:
            return {
                ...state,
                company_ratings: action.payload
            }
        case COMPANY_SUMMARY:
            return {
                ...state,
                company_summary: action.payload
            }
        case COMPANY_NEWS:
            return {
                ...state,
                company_news: action.payload
            }
        case MARKET_HOURS:
            return {
                ...state,
                market_hours: action.payload
            }
        case PRE_POST_MARKET_TRADE:
            return {
                ...state,
                pre_post_market_trade: action.payload
            }
        default:
            return state;
    }
};
