import { get, putpost } from './apiClient';
import { addUserId } from '.';

export const signIn = (username, password) => {
  return putpost('/api/signin', 'POST', {'username': username, 'password': password}).then((res) => {
  	return res;
  }).catch(err =>{
  	console.log(err);
  })
}

export const socialLogin = (accessToken, backend) => {
  return putpost('/api/social-login', 'POST', {'access_token': accessToken, 'backend': backend}).then((res) => {
  	return res;
  }).catch(err =>{
  	console.log(err);
  })
}

export const getGoogleUser = async (accessToken) => {
  let endpoint = '/api/get_google_user_profile/'+ accessToken;
  let response = await get(endpoint, "GET", false);
  const data = await response;
  if (response.status && response.status !== 200) throw Error(response.message);
  return data;
}

export const signOut = () => {
  return get('/api/signout', 'GET').then((res) => {
  	return res;
  }).catch(err => {
  	console.log(err);
  })
}

export const isAuthenticated = () => {
  return get('/api/issignedin', 'GET').then((res) => {
  	return res;	
  }).catch(err => {
  	console.log(err);
  })
}