import React, { useEffect, useState } from 'react';
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {blue, grey} from "@material-ui/core/colors";



const useStyles = makeStyles(theme => ({
    newsItemContainer: {
      marginBottom: "20px",
      '&:hover': {
          backgroundColor: grey[200],
          cursor: "pointer",
          '& $customTypographyH6': {
            color: blue[800]
          }
      }
    },
    customTypographyH6: {
        fontWeight: "bold"
    },
    customUnderlineHover: {
        color: "blue"
    },
    customTypographyCaption: {
        marginLeft: "10px",
        fontSize: "10px"
    }
}));

const CompanyNews = (props) => {
    const classes = useStyles();
    const state = useSelector(state => state);
    const [currentNews, setCurrentNews] = useState([]);

    useEffect(() => {
        if(state.companyInfo?.company_news)
            setCurrentNews(state.companyInfo?.company_news);
    },[state.companyInfo?.company_news]);

    return (
        <>
            {
                currentNews && currentNews.length > 0 ?
                currentNews.map((item, index) => {
                   return (
                       <div className={classes.newsItemContainer}>
                           <Link to={item.url} target="_blank">
                               <Typography className={classes.newsTitle} gutterBottom variant="h6" classes={{h6: classes.customTypographyH6}}>
                                   { item.title }
                               </Typography>
                               <Typography gutterBottom variant="subtitle2">
                                   { item.text }
                                   <Typography gutterBottom variant="caption" classes={{caption: classes.customTypographyCaption}}>
                                        { item.publishedDate }
                                   </Typography>
                               </Typography>
                           </Link>
                       </div>
                   )
                }):
                    <div>
                        <Typography gutterBottom variant="overline">
                            No news for this company
                        </Typography>
                    </div>
            }
        </>
    );
}

export default CompanyNews;