import React from 'react';
import {Routes, Route, Navigate} from "react-router-dom";
import { useSelector } from "react-redux";

import PrivateRoutes from "./PrivateRoutes";
import Dashboard from '../pages/Dashboard';
import LoginForm from '../pages/LoginForm';
import FeedbackForm from '../pages/FeedbackForm';
import IncomeStatement from '../pages/IncomeStatement';
import BalanceSheet from '../pages/BalanceSheet';
import CashFlow from '../pages/CashFlow';
import QuarterlyAndAnnualReport from '../pages/QuarterlyAndAnnualReport';
import Other from '../pages/Other';
import CompanyGrowth from '../pages/CompanyGrowth';
import Account from '../Account/index';
import Privacy from '../pages/Privacy';
import MySelection from "../pages/MySelection";
import Market from "../pages/Market";


const Router = () => {
	const state = useSelector(state => state);

	return (
		<>
			<Routes>
				<Route path="/" element={<Dashboard/>} />
				{ state.application.showPrivateLinks == "true" ? (
					<Route path="/login" element={<LoginForm/>} />
				): (
					<Route path="/login" element={ <Navigate to='/' /> } />
				)}
				<Route path="/" element={<PrivateRoutes />}>
				    <Route path="/feedback" element={<FeedbackForm/>} />
				    <Route path="/account" element={<Account/>} />
				</Route>
				{ state.application.showPrivateLinks == "true" ? (
					<Route path="/privacy" element={<Privacy/>} />
					):(
						<Route path="/privacy" element={ <Navigate to='/' /> } />
					)
				}
                <Route element={<Dashboard/>}>
                    <Route path="income" element={<IncomeStatement />} />
                    <Route path="balancesheet" element={<BalanceSheet/>} />
                    <Route path="cashflow" element={<CashFlow/>} />
                    <Route path="quarterlyannualreport" element={<QuarterlyAndAnnualReport/>}>
						<Route element={<PrivateRoutes />}>
							<Route path="mySelection" element={<MySelection/>} />
						</Route>
					</Route>
                    <Route path="other" element={<Other/>} />
					<Route path="companygrowth" element={<CompanyGrowth/>} />
					<Route path="market" element={<Market/>} />
                </Route>
			</Routes>
		</>
		);
}

export default Router;