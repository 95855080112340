import {
    ADD_UPDATE_INCOME,
    UPDATE_INCOME_REVENUE,
    ADD_BALANCE_SHEET,
    ADD_UPDATE_BALANCE_SHEET,
    ADD_UPDATE_CASH_FLOW,
    ADD_INCOME_HISTORY,
    ADD_BALANCE_SHEET_HISTORY,
    ADD_CASH_FLOW_HISTORY,
    ADD_ALL_DATE_REPORTED,
    ADD_UPDATE_CHART_INDEX,
    ADD_FINANCIAL_STATEMENTS_HELP_DESC,
    ADD_SEC_REPORT,
    REMOVE_SEC_REPORT,
    ADD_COMPANY_PROFILE,
    ADD_CURRENCY_REPORTED,
    SAVE_USER_SELECTED_SEC_REPORT,
    ADD_ALL_INCOME_HISTORY,
    ADD_ALL_BALANCE_SHEET_HISTORY,
    ADD_ALL_CASH_FLOW_HISTORY,
    ADD_COMPANY_EARNINGS_CALENDAR,
    DELETE_USER_SELECTION,
    ADD_USER_SELECTION,
    REVENUE_BY_PRODUCT_SEGMENT,
    REVENUE_BY_GEO_SEGMENT
} from '../constants/actionTypes';

export const income = (state = {}, action) =>{
  switch(action.type){
    case ADD_UPDATE_INCOME:
        return {
            ...state,
            data: action.payload
            }
    case UPDATE_INCOME_REVENUE:
        return {
            ...state,
            data: { ...state.data, revenue: action.payload }
        }
    default:
        return state;
  }
};

export const balance_sheet = (state = {}, action) => {
  switch(action.type){
    case ADD_UPDATE_BALANCE_SHEET:
        return {
            ...state,
            data: action.payload
        }    
    default:
        return state;
  }
};

export const cash_flow = (state = {}, action) => {
  switch(action.type){
    case ADD_UPDATE_CASH_FLOW:
        return {
            ...state,
            data: action.payload
        }
    default:
        return state;
  }
};

export const cash_flow_history = (state = {}, action) => {
  switch(action.type){
    case ADD_CASH_FLOW_HISTORY:
        return {
            ...state,
            data: action.payload
        }
    default:
        return state;
  }
};

export const income_history = (state = {}, action) => {
  switch(action.type){
    case ADD_INCOME_HISTORY:
        return {
            ...state,
            data: action.payload
        }
    default:
        return state;
  }
};

export const balance_sheet_history = (state = {}, action) => {
  switch(action.type){
    case ADD_BALANCE_SHEET_HISTORY:
        return {
            ...state,
            data: action.payload
        }
    default:
        return state;
  }
};

export const all_financial_history = (state = {}, action) => {
    switch(action.type){
        case ADD_ALL_INCOME_HISTORY:
            return {
                ...state,
                income: action.payload
            }
        case ADD_ALL_BALANCE_SHEET_HISTORY:
            return {
                ...state,
                balanceSheet: action.payload
            }
        case ADD_ALL_CASH_FLOW_HISTORY:
            return {
                ...state,
                cashFlow: action.payload
            }
        case REVENUE_BY_PRODUCT_SEGMENT:
            return {
                ...state,
                revenueByProductSegment: action.payload
            }
        case REVENUE_BY_GEO_SEGMENT:
            return {
                ...state,
                revenueByGeoSegment: action.payload
            }
        default:
            return state;
    }
};

export const currency_reported = (state = {}, action) => {
  switch(action.type){
    case ADD_CURRENCY_REPORTED:
        return {
            ...state,
            value: action.payload
        }
    default:
        return state;
  }
};

export const all_date_reported = (state = {}, action) => {
  switch(action.type){
    case ADD_ALL_DATE_REPORTED:
        return {
            ...state,
            data: action.payload
        }
    default:
        return state;
  }
};

export const chart = (state = {}, action) => {
  switch(action.type){
    case ADD_UPDATE_CHART_INDEX:
        return {
            ...state,
            index: action.payload
        }
    default:
        return state;
  }
};

export const financial_statements_help_desc = (state = {}, action) =>{
  switch(action.type){
    case ADD_FINANCIAL_STATEMENTS_HELP_DESC:
        return {
            ...state,
            data: action.payload
            }
    default:
        return state;
  }
};

export const company_profile = (state = {}, action) =>{
  switch(action.type){
    case ADD_COMPANY_PROFILE:
        return {
            ...state,
            data: action.payload
        }
    default:
        return state;
  }
};

export const company_earnings_calendar = (state = {}, action) =>{
    switch(action.type){
        case ADD_COMPANY_EARNINGS_CALENDAR:
            return {
                ...state,
                data: action.payload
            }
        default:
            return state;
    }
};

export const sec_report = (state = {}, action) =>{
  switch(action.type){
    case ADD_SEC_REPORT:
        return {
            ...state,
            data: action.payload
            }
    case REMOVE_SEC_REPORT:
        return {
            ...state,
            data: '',
            user_selection: []
            }
    case SAVE_USER_SELECTED_SEC_REPORT:
        return {
            ...state,
            user_selection: action.payload
        }
    case ADD_USER_SELECTION:
          return {
              ...state,
              user_selection: [...state.user_selection, ...action.payload]
          }
    case DELETE_USER_SELECTION:
        return {
            ...state,
            user_selection: [...state.user_selection.filter(item => item.id !== action.payload)]
        }
    default:
        return state;
  }
};

