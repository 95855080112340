import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";

import Typography from "@material-ui/core/Typography";
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';

import {formatNumberForChart} from "../../utils";
import SimpleBarChart from "../common/SimpleBarChart";
import SimpleLineChart from "../common/SimpleLineChart";


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
    },
    gridRoot: {
        flexGrow: 1,
    },
    bodyContainer: {
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    chartContainer: {
        height: "300px"
    },
    subTitleMarginTop: {
        marginTop: "15px"
    },
    subChartContainer: {
        height: "100%",
        width: "100%"
    },
    whiteSpaceBottom: {
        height: "40px"
    },
    info: {
        fontSize: "11px",
        fontWeight: "bold"
    }
}));

const CompanyGrowth = (props) => {
    const classes = useStyles();
    const state = useSelector(state => state);
    const [historicalData, setHistoricalData] = useState([]);
    const [revenueProductSegment, setRevenueProductSegment] = useState([]);
    const [revenueGeoSegment, setRevenueGeoSegment] = useState([]);

    const getRevenueBySegment = (revenueSegmentData, revenueSegmentType) => {
        let revenueSegmentDataList = [];
        if(revenueSegmentData && revenueSegmentData.length > 0) {
            const sortedList = revenueSegmentData.toReversed();
            sortedList.map(item => {
                let revenueSegmentDataItem = {};
                for (const [key, value] of Object.entries(item)) {
                    revenueSegmentDataItem.key = key;
                    for (const [key1, value1] of Object.entries(value))
                        revenueSegmentDataItem[key1] = formatNumberForChart(value1);
                }
                revenueSegmentDataList.push(revenueSegmentDataItem);
            });
            return revenueSegmentDataList;
        }
    }

    useEffect(() => {
       if(state.allFinancialHistory.income) {
           let histData = [];
           let totalStatementDataLength = state.allFinancialHistory.income[0].revenue ? state.allFinancialHistory.income[0].revenue.length:
               state.allFinancialHistory.income[0].net_income.length;
           for(let i=0; i<totalStatementDataLength; i++) {
               let itemData = {};
               Object.values(state.allFinancialHistory.income).forEach((statementType) => {
                   itemData.period = statementType.revenue && statementType.revenue[i].date ? statementType.revenue[i].date : itemData.period;
                   itemData.revenue = statementType.revenue && statementType.revenue[i].value ? formatNumberForChart(statementType.revenue[i].value) : itemData.revenue;
                   itemData.netIncome = statementType.net_income && statementType.net_income[i].value ? formatNumberForChart(statementType.net_income[i].value) : itemData.netIncome;
                   itemData.operatingExpense = statementType.operating_expense && statementType.operating_expense[i].value ? formatNumberForChart(statementType.operating_expense[i].value) : itemData.operatingExpense;
               });
               histData.push(itemData);
           }
           setHistoricalData(histData);
       }
    },[state.allFinancialHistory.income]);

    useEffect(() => {
        const revenueProductSegmentList = getRevenueBySegment(state.allFinancialHistory.revenueByProductSegment);
        if(revenueProductSegmentList && revenueProductSegmentList.length > 0)
            setRevenueProductSegment(revenueProductSegmentList);
    }, [state.allFinancialHistory.revenueByProductSegment]);

    useEffect(() => {
        const revenueGeoSegmentList = getRevenueBySegment(state.allFinancialHistory.revenueByGeoSegment);
        if(revenueGeoSegmentList && revenueGeoSegmentList.length > 0)
            setRevenueGeoSegment(revenueGeoSegmentList);
    }, [state.allFinancialHistory.revenueByGeoSegment]);

    return (
            <div className={classes.chartContainer}>
                {
                    historicalData && historicalData.length > 0 && (
                    <>
                        <div>
                            <Typography gutterBottom variant="caption">
                                Numbers are in millions. Currency Reported: {state.currencyReported.value}
                            </Typography>
                            <div className={classes.info}>
                                (Move sliders on both ends to zoom in or zoom out datasets on charts below.
                                You can also click or tap in the middle of the range and drag left or right)
                            </div>
                        </div>
                        <div className={classes.subTitleMarginTop}>
                            <Typography gutterBottom variant="subtitle2">
                                Growth Overtime
                            </Typography>
                        </div>
                        <SimpleLineChart data={historicalData} />
                    </>
                    )
                }
                {
                    revenueProductSegment && revenueProductSegment.length > 0 && (
                      <>
                        <div className={classes.subTitleMarginTop}>
                                <Typography gutterBottom variant="subtitle2">
                                    Revenue By Product Segment
                                </Typography>
                        </div>
                        <SimpleBarChart data={revenueProductSegment} showLegend={false}/>
                      </>
                    )
                }
                { revenueGeoSegment && revenueGeoSegment.length > 0 &&
                    (
                        <>
                            <div className={classes.subTitleMarginTop}>
                                        <Typography gutterBottom variant="subtitle2">
                                            Revenue By Geography Segment
                                        </Typography>
                            </div>
                            <SimpleBarChart data={revenueGeoSegment} showLegend={false}/>
                        </>
                    )
                }
                {
                    !historicalData && !revenueProductSegment && !revenueGeoSegment && (
                        <div>
                            <Typography gutterBottom variant="overline">
                                No historical data available for this company
                            </Typography>
                        </div>
                    )
                }
                <div className={classes.whiteSpaceBottom}></div>
            </div>
    );
};

export default CompanyGrowth;