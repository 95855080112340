import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from "react-redux";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {
    addUpdateIncome,
    addUpdateChartIndex,
    removeSECReport,
    updateCurrentStatementTab,
    onDateSelection
} from '../../actions';
import SimpleDataDisplay from '../common/SimpleDataDisplay';
import { dataExist } from '../../constants/CommonUtils';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card : {
    marginLeft: 15,
    display: 'inline-block',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  description: {
    fontSize: 12,
  },
  section: {
    marginTop: 10,
  }
}));

const IncomeStatement = props => {
    const classes = useStyles();
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [dataList, setDataList] = React.useState([]);
    const [revenueDataList, setRevenueDataList] = React.useState([]);
    const [expenseDataList, setExpenseDataList] = React.useState([]);
    const [operatingIncome, setOperatingIncome] = React.useState({});
    const [interestAndTaxesDataList, setInterestAndTaxesDataList] = React.useState([]);
    const [netIncome, setNetIncome] = React.useState({});
    const [earningsPerShareList, setEarningsPerShareList] = React.useState([]);
    const [sharesOutstandingList, setSharesOutstandingList] = React.useState([]);
    const [grossMargin, setGrossMargin] = React.useState(0);
    const [sgaExpense, setSGAExpense] = React.useState(0);
    const [grossProfitOnSGAExpense, setGrossProfitOnSGAExpense] = React.useState(0);
    const [grossProfitOnRnDExpense, setGrossProfitOnRnDExpense] = React.useState(0);
    const [grossProfitOnOpExpense, setGrossProfitOnOpExpense] = React.useState(0);
    const [activeChartIndex, setActiveChartIndex] = React.useState(0);
    const subTitleText = "Numbers are in millions except for EPS";

    React.useEffect(() => {
      if(state && state.incomeStatement && state.incomeStatement.data){
        let grossProfit = state.incomeStatement.data.gross_profit;
        let revenue = state.incomeStatement.data.revenue;
        let sgaExpense = state.incomeStatement.data.sga_expense;
        let rndExpense = state.incomeStatement.data.research_and_development;
        let opExpense = state.incomeStatement.data.operating_expense;

        if(grossProfit > 0 && revenue > 0)
            setGrossMargin(grossProfit/revenue);
        setSGAExpense(sgaExpense);
        if(sgaExpense > 0 && grossProfit > 0)
            setGrossProfitOnSGAExpense(((sgaExpense/grossProfit) * 100).toFixed(2));
        if(rndExpense > 0 && grossProfit > 0)
            setGrossProfitOnRnDExpense(((rndExpense/grossProfit) * 100).toFixed(2));
        if(opExpense > 0 && grossProfit > 0)
            setGrossProfitOnOpExpense(((opExpense/grossProfit) * 100).toFixed(2))
      }
    }, [state.incomeStatement]);

    const handleItemChartClick = (selectedIndex) => {
      setActiveChartIndex(selectedIndex);
      dispatch(addUpdateChartIndex(selectedIndex));

      let currentIncomeStatement = state.incomeStatement.data;
      state.incomeStatementHistory.data.forEach(function(item, index){
          if(item){
            let itemDataSet = {};
            for (let [key, value] of Object.entries(item)) {
              let keyName = `${key}`;
              if(value && value[selectedIndex]){
                currentIncomeStatement[keyName] = value[selectedIndex].value;
              }
            }
          }
        });
      dispatch(addUpdateIncome(currentIncomeStatement));
      dispatch(updateCurrentStatementTab("income"));
      dispatch(removeSECReport());
    };

    React.useEffect(() => {
      if(state.chart && state.chart.index > -1){
        setActiveChartIndex(state.chart.index);
      }
    },[state.chart]);

    const restructureDataForDashboard = () => {
      if(state && state.incomeStatementHistory && state.incomeStatementHistory.data){
        setRevenueDataList([]);
        setExpenseDataList([]);
        setOperatingIncome({});
        setInterestAndTaxesDataList([]);
        setNetIncome({});
        setEarningsPerShareList([]);
        setSharesOutstandingList([]);
        state.incomeStatementHistory.data.forEach(function(item, index){
          if(item){
            let itemDataSet = {};
            for (let [key, value] of Object.entries(item)) {
              itemDataSet = {
                title: `${key}`,
                data: value,
                id: `${key}` + "_" + index
              }
              let keyName = `${key}`;
              if(keyName === 'revenue' || keyName === 'cost_of_revenue' || keyName === 'gross_profit'){
                setRevenueDataList(revenueDataList => [...revenueDataList, itemDataSet]);
              }
              else if(keyName === 'sga_expense' || 
                keyName === 'research_and_development' || keyName === 'operating_expense'){
                setExpenseDataList(expenseDataList => [...expenseDataList, itemDataSet]);
              }
              else if(keyName === 'operating_income'){
                setOperatingIncome(itemDataSet)
              }
              else if(keyName === 'interest_expense' || 
                keyName === 'earnings_before_tax' || keyName === 'income_tax_expense'){
                setInterestAndTaxesDataList(interestAndTaxesDataList => [...interestAndTaxesDataList, itemDataSet]);
              }
              else if(keyName === 'net_income'){
                setNetIncome(itemDataSet);
              }
              else if(keyName === 'eps' || keyName === 'eps_diluted'){
                setEarningsPerShareList(earningsPerShareList => [...earningsPerShareList, itemDataSet]);
              }
              else if(keyName === 'basic_shares_outstanding' || keyName === 'diluted_shares_outstanding'){
                setSharesOutstandingList(sharesOutstandingList => [...sharesOutstandingList, itemDataSet]);                
              }
            }
          }
        });
      }
    };

    React.useEffect(() => {
      if(state.incomeStatementHistory && state.incomeStatementHistory.data){
        restructureDataForDashboard();
      }
    },[state.incomeStatementHistory]);

    React.useEffect(()=> {
        if(state.application.onDateSelection) {
            handleItemChartClick(0);
            dispatch(onDateSelection(false));
        }
    },[state.application.onDateSelection]);

    return (
          <div className="container__wrap">
              {
                state && state.incomeStatement && state.incomeStatement.data && (
                  <div class="financial_statement_title">
                    <h6>
                      Income Statement: Profit & Loss Statement, the statement of operations and the statement of income.
                      The sole purpose is to show a company's profitability over a given period of time.
                      <br/>
                      ({subTitleText.toUpperCase()})
                      <br/>
                      Currency Reported: {state.currencyReported.value}
                    </h6>
                  </div>
                )
              }
            <div className="dashboard">
              <div>
              {
                revenueDataList && revenueDataList.length > 0 && (
                  revenueDataList.map(function(item, index){
                    if(dataExist(item.data)){
                      return <SimpleDataDisplay key={item.id} id={item.id} data={item.data} title={item.title}
                      dir={state.rtl.direction} help={item.title} 
                      onItemChartSelection={handleItemChartClick}
                      activeChartIndex={activeChartIndex}
                      />;
                    }
                  })
                )
              }
              </div>
              <div>
              {
                grossMargin > 0 && (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h5" component="h2" className={classes.title} color="textSecondary" gutterBottom>
                        Gross Profit Margin: {(grossMargin * 100).toFixed(2) }% 
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.description}>
                        Company made ${(grossMargin * 100).toFixed(2)} in gross profit off of $100 of sales.
                      </Typography>
                    </CardContent>
                  </Card>
                )
              }
              </div>
              <div className={classes.section}>
              {
                expenseDataList && expenseDataList.length > 0 && (
                  expenseDataList.map(function(item, index){
                    if(dataExist(item.data)){
                      return <SimpleDataDisplay key={item.id} id={item.id} data={item.data}
                              title={item.title} dir={state.rtl.direction} help={item.title} 
                              onItemChartSelection={handleItemChartClick}
                              activeChartIndex={activeChartIndex}
                              />;
                      }
                  })
                )
              }
              </div>
              <div>
              {
                grossProfitOnSGAExpense > 0 && (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h5" component="h2" className={classes.title} color="textSecondary" gutterBottom>
                        % Gross Profit On SGA Expense: {
                          grossProfitOnSGAExpense
                        }%
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.description}>
                        Company spend {grossProfitOnSGAExpense}% of gross profit on SGA Expense.
                      </Typography>
                    </CardContent>
                  </Card>
                )
              }
              {
                grossProfitOnRnDExpense > 0 && (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h5" component="h2" className={classes.title} color="textSecondary" gutterBottom>
                        % Gross Profit On Research and Development Expense: {
                          grossProfitOnRnDExpense
                        }%
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.description}>
                        Company spend {grossProfitOnRnDExpense}% of gross profit on Research and Development Expense.
                      </Typography>
                    </CardContent>
                  </Card>
                )
              }
              {
                grossProfitOnOpExpense > 0 && (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h5" component="h2" className={classes.title} color="textSecondary" gutterBottom>
                        % Gross Profit On Operating Expense: {
                          grossProfitOnOpExpense
                        }%
                      </Typography>
                      <Typography variant="body2" component="p" className={classes.description}>
                        Company spend {grossProfitOnOpExpense}% of gross profit on Operating Expense.
                      </Typography>
                    </CardContent>
                  </Card>
                )
              }
              </div>
              <div className={classes.section}>
              {
                operatingIncome && operatingIncome.data && (
                    <SimpleDataDisplay key={0} id={0} data={operatingIncome.data}
                        title={operatingIncome.title} dir={state.rtl.direction} help={operatingIncome.title} 
                        onItemChartSelection={handleItemChartClick}
                        activeChartIndex={activeChartIndex}
                        />
                )
              }
              </div>
              <div className={classes.section}>
              {
                interestAndTaxesDataList && interestAndTaxesDataList.length > 0 && (
                  interestAndTaxesDataList.map(function(item, index){
                    if(dataExist(item.data)){
                      return <SimpleDataDisplay key={item.id} id={item.id} data={item.data}
                              title={item.title} dir={state.rtl.direction} help={item.title} 
                              onItemChartSelection={handleItemChartClick}
                              activeChartIndex={activeChartIndex}
                              />;
                      }
                  })
                )
              }
              </div>
              <div className={classes.section}>
              {
                netIncome && netIncome.data && (
                    <SimpleDataDisplay key={netIncome.id} id={netIncome.id} data={netIncome.data}
                        title={netIncome.title} dir={state.rtl.direction} help={netIncome.title} 
                        onItemChartSelection={handleItemChartClick}
                        activeChartIndex={activeChartIndex}
                        />
                )
              }
              </div>
              <div className={classes.section}>
              {
                earningsPerShareList && earningsPerShareList.length > 0 && (
                  earningsPerShareList.map(function(item, index){
                    if(dataExist(item.data)){
                      return <SimpleDataDisplay key={item.id} id={item.id} data={item.data}
                              title={item.title} dir={state.rtl.direction} help={item.title} 
                              onItemChartSelection={handleItemChartClick}
                              activeChartIndex={activeChartIndex}
                              />;
                      }
                  })
                )
              }
              </div>
              <div className={classes.section}>
              {
                sharesOutstandingList && sharesOutstandingList.length > 0 && (
                  sharesOutstandingList.map(function(item, index){
                    if(dataExist(item.data)){
                      return <SimpleDataDisplay key={item.id} id={item.id} data={item.data}
                              title={item.title} dir={state.rtl.direction} help={item.title} 
                              onItemChartSelection={handleItemChartClick}
                              activeChartIndex={activeChartIndex}
                              />;
                      }
                  })
                )
              }
              </div>
            </div>
          </div>
    );
}

export default IncomeStatement;