import React, {useEffect, useRef, useState} from 'react';

import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Autosuggest from 'react-autosuggest';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import SimpleSpinner from '../common/SimpleSpinner';
import { get, putpost } from '../../actions/apiClient';
import {
	addSymbol,
	addFinancialStatementsHelpDesc,
	removeSECReport,
	logOut,
	reduxStoreInitialize,
	getCompanySummary,
	getPrePostMarketTrade,
	getMarketHours, addCompanySummary, getAllSymbols, subscribeToNewSymbolWS
} from '../../actions';
import * as constants from '../../constants/CommonConstants';
import {
	ANNUAL,
	EXTENDED_HOURS_POLLING_TIME,
	INTRADAY_POLLING_TIME,
	QUARTER,
	TOTAL_NO_PAST_SUGGESTIONS
} from "../../constants/CommonConstants";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import IncomeStatement from "../pages/IncomeStatement";
import BalanceSheet from "../pages/BalanceSheet";
import QuarterlyAndAnnualReport from "../pages/QuarterlyAndAnnualReport";
import CashFlow from "../pages/CashFlow";
import Other from "../pages/Other";
import Market from "../pages/Market";
import Grid from "@material-ui/core/Grid";
import {
	extendedHoursClosedDuration, findSymbols,
	isExtendedHours,
	isIntraday,
	isMarketHolidayToday
} from "../../constants/CommonUtils";
import {getTZTime, IANA_TZ} from "../../utils";


const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: '20px',
	},
	navContainer: {
		backgroundColor: "#f2f4f7",
		paddingTop: "25px",
		marginLeft: "20%",
		marginRight: "20%"
	},
	subHeader: {
		position: 'fixed',
		width: '100%',
		zIndex: '1000',
		'@media screen and (min-width: 576px)': {
			paddingLeft: '60px'
		}
	},
	fab: {
    	margin: '0px',
    	top: 'auto',
    	right: '20px',
    	bottom: '20px',
    	left: 'auto',
    	position: 'fixed'
  	},
	subNavActive: {
		fontWeight: "bold",
		fontSize: "14px",
		borderBottom: "2px solid black"
	},
	subNavLink: {
		display: "inline-block",
		marginLeft: "100px"
	},
	subNavAlign: {
		marginLeft: "25px",
	},
	bodyContainer: {
	},
	title: {
		backgroundColor: "#f2f4f7",
		paddingTop: "10px",
		marginLeft: "20%"
	},
	switchRoot: {
		display: "inline-block",
		height: "30px",
		float: "right"
	},
	dashboardAlignment: {
		marginLeft: "190px"
	},
	switchBase: {
		position: "relative"
	},
	muiSwitchRoot:{
		width: "68px"
	},
	muiTabRoot: {
		'@media (min-width: 600px)': {
			minWidth: "50px"
		},
		textTransform: "none"
	},
	muiTabs_scrollButtonsDesktop: {
		'@media (max-width: 599.95px)':{
			display: 'flex'
		}
	},
	tableContainer: {
		display: "table"
	},
	tableRow: {
		display: "table-row"
	},
	tableColumn: {
		display: "table-cell"
	},
	customTypographySubtitle2: {
		fontWeight: "bold"
	},
	customTypographyH4: {
		fontWeight: "bold"
	},
	priceChanges: {
		marginLeft: "10px"
	},
	priceContainer: {
		paddingTop: "5px"
	},
	positiveChanges: {
		color: "green"
	},
	negativeChanges: {
		color: "red"
	},
	customTypographyCaption: {
		fontSize: "10px",
		display: "block"
	}
}));

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					{children}
				</Box>
			)}
		</div>
	);
};

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`,
	};
};

const SearchBar = (props) => {
	const classes = useStyles();
    const dispatch = useDispatch();
	const state = useSelector(state => state);
	const [suggestions, setSuggestions] = React.useState([]);
	const [suggestionSelected, setSuggestionSelected] = React.useState({});
	const [quarterlyReport, setQuarterlyReport] = React.useState(null);
	const { loading } = props;
	const autoSuggestInputFieldRef = React.createRef();
	const [marketOpen, setMarketOpen] = useState(false);
	const [afterMarketClosed, setAfterMarketClosed] = useState(false);
	const [intradayTrade, setIntradayTrade] = useState(null);
	const [prePostTrade, setPrePostTrade] = useState(null);
	const [companySummary, setCompanySummary] = useState(null);
	const [marketHours, setMarketHours] = useState(null);
	const timeOutRef = useRef(null);
	const [screenSize, setScreenSize] = React.useState(window.innerWidth);
	const { onNavigation, getAllFinancialStatements, getAllDateReported,
	    value, onChange, onBlur, setSuggestionSelectedValue, onLogOutCleanUp } = props;
	const [tabValue, setTabValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
	};

    const toggleSwitchReportType = () => {
        setQuarterlyReport(prev => !prev);
		setTabValue(1);
    };

	const isQuarterlyReport = (reportType) => {
		return reportType === QUARTER;
	};

	const getReportType = (isQuarterlyReport) => {
		return isQuarterlyReport? QUARTER: ANNUAL;
	};

	const isReportTypeChanged = (currentReportType) => {
		return currentReportType !== localStorage.getItem(constants.REPORT_TYPE);
	};

    const setReportType = () => {
        let reportType = quarterlyReport != null ? getReportType(quarterlyReport):
			localStorage.getItem(constants.REPORT_TYPE);
		if(isReportTypeChanged(reportType)) {
			localStorage.setItem(constants.REPORT_TYPE, reportType);
		}
		if(quarterlyReport === null)
			setQuarterlyReport(isQuarterlyReport(reportType));

		props.onToggleReportType(reportType);
    };

	const getSymbolData = (reportType) => {
		let symbolSelected = localStorage.getItem(constants.SYMBOL_SELECTED);
		if (symbolSelected) {
			subscribeToNewSymbol("", JSON.parse(symbolSelected).symbol);
			let suggestion = JSON.parse(symbolSelected);
			if (suggestion)
				getAllFinancialDataByReportType(suggestion, reportType);
		}
	};

	const onSuggestionsFetchRequested = ({ value, reason }) => {
		if(reason === 'input-changed'){
			setSuggestionSelectedValue(false);
			const symbols = findSymbols(value, state.application_symbols.allSymbols);
			setSuggestions(symbols || []);
			/*
			var endpoint = '/api/symbols/'+value;
			get(endpoint, "GET").then((res) => {
			  setSuggestions(res || []);
			})
			.catch(err => {
				handleError(err);
			});
			 */
		}
	};

	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const getCurrentStateReportType = () => {
		if(quarterlyReport === null)
			return null;
		return quarterlyReport? QUARTER: ANNUAL;
	};

	const onSuggestionSelected = (event, {suggestion, suggestionValue}) => {
		let reportType = localStorage.getItem(constants.REPORT_TYPE) || "";
		let currentReportType = getCurrentStateReportType();
		if (currentReportType && reportType !== currentReportType) {
			localStorage.setItem(constants.REPORT_TYPE, currentReportType);
			reportType = currentReportType;
		}
		if (suggestion) {
			SetPastSuggestions(suggestion);
			if (suggestion.symbol) {
				const prevSymbol = state?.symbol?.data?.symbol;
				subscribeToNewSymbol(prevSymbol, suggestion.symbol);
				reduxStoreInitialize(dispatch);
				getAllFinancialDataByReportType(suggestion, reportType);
			}
		}
	};

	const subscribeToNewSymbol = (prevSymbol, newSymbol) => {
		sendJsonMessage(prevSymbol + "|" + newSymbol);
	}

	const getAllFinancialDataByReportType = (suggestion, reportType) => {
		dispatch(addSymbol(suggestion));
		setSymbolSelected(suggestion);
		dispatch(removeSECReport());
		getAllFinancialStatements(suggestion.symbol, true, 'none', reportType);
		getAllDateReported(suggestion.symbol, reportType);
		getAllFinancialStatementsHelpDesc();
	};

	const SetPastSuggestions = (suggestion) => {
		let suggestionList = [];
		const pastSuggestionList = localStorage.getItem(constants.PAST_SUGGESTIONS);
		if(pastSuggestionList) {
			suggestionList = JSON.parse(pastSuggestionList);
			suggestionList = suggestionList.filter(item => item.symbol !== suggestion.symbol);
		}
		suggestionList.push(suggestion);
		if(suggestionList.length > TOTAL_NO_PAST_SUGGESTIONS)
			suggestionList.shift();
		localStorage.setItem(constants.PAST_SUGGESTIONS, JSON.stringify(suggestionList));
	};

	const setSymbolSelected = (suggestion) => {
		localStorage.setItem(constants.SYMBOL_SELECTED, JSON.stringify(suggestion));
		setSuggestionSelected(suggestion);
	};

	const getAllFinancialStatementsHelpDesc = () => {
		var endpoint = '/api/corefinancials/get_financial_help_desc';
		get(endpoint, "GET").then((res) => {
		  dispatch(addFinancialStatementsHelpDesc(res));
		})
		.catch(err => {
		  console.log(err);
		});
	};

	const getSuggestionValue = suggestion => suggestion.name;

	const shouldRenderSuggestions = (value) => {
  		return value.trim().length > -1;
	};

	const onMouseDown = () => {
		const pastSuggestionList = localStorage.getItem(constants.PAST_SUGGESTIONS);
		if(pastSuggestionList) {
			const pastSuggestionListObj = [] = JSON.parse(pastSuggestionList);
			setSuggestions(pastSuggestionListObj.reverse());
		}
	};

	const inputProps = {
		placeholder: 'Search by symbol or company name',
		value,
		onChange: onChange,
		onBlur: onBlur,
		onMouseDown: onMouseDown
	};

	const renderSuggestion = suggestion => (
		<div>
			<div>
		  		{suggestion.name} ({suggestion.symbol})
		  	</div>
			<div>
		  		{suggestion.exchange}
		  	</div>
		</div>
	);

	const handleError = (err) => {
		if(err.message && err.message === "expired_token"){
			onLogOutCleanUp();
			logOut(dispatch);
		}
		console.log(err);
	};

	const [socketUrl] = useState(() => {
		const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
		const host = window.location.hostname;
		const port = window.location.port ? `:${window.location.port}` : '';
		return `${protocol}://${host}${port}/ws`;
	});
	const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl);

	useEffect(() => {
		console.log("Connection state changed");
		if (readyState === ReadyState.OPEN) {
			console.log("WS Connection successful");
		}
	}, [readyState]);


	// Run when a new WebSocket message is received (lastJsonMessage)
	useEffect(() => {
		if(lastJsonMessage && lastJsonMessage.type === "T" && state.companyInfo?.company_summary) {
			const { lp, ls } = lastJsonMessage;
			const { previousClose, sharesOutstanding } = state.companyInfo?.company_summary[0];
			const changes = lp - previousClose;
			const changePercentage = ((changes / previousClose) * 100);

			let updatedSummary = [
				{
					...state.companyInfo?.company_summary[0],
					price: lp,
					change: changes,
					changePercentage: changePercentage,
					marketCap: sharesOutstanding * lp,
					lastSalePrice: lp,
					lastSaleSize: ls
				},
				...state.companyInfo?.company_summary.slice(1)
			];
			//const finalUpdateSummary = updatedSummary;
			dispatch(addCompanySummary(updatedSummary));
		}
	}, [lastJsonMessage]);

    React.useEffect(() => {
		const symbolData = state.symbol?.data;
		const reportType = getReportType(quarterlyReport);
		if(!symbolData || isReportTypeChanged(reportType)) {
			setReportType();
			getSymbolData(reportType);
		}
    },[quarterlyReport]);

	useEffect(() => {
		const currentSymbol = state?.symbol?.data?.symbol;
		if(currentSymbol && !state.companyInfo?.market_hours) {
			getMarketHours(dispatch).then(res => {
				setMarketHours(res);
			});
		}
	},[state?.symbol?.data?.symbol]);

	const companySummaryPolling = () => {
		clearTimeout(timeOutRef.current);
		timeOutRef.current = null;

		if (isExtendedHours()) {
			getPrePostMarketTrade(state?.symbol?.data?.symbol, dispatch).then(res => {
				if (res && res.symbol) {
					timeOutRef.current = setTimeout(function () {
						companySummaryPolling(state?.symbol?.data?.symbol)
					}, EXTENDED_HOURS_POLLING_TIME);
				}
			});
		}
	};

	useEffect(() => {
		if (state.companyInfo?.market_hours) {
			const currentSymbol = state?.symbol?.data?.symbol;
			clearTimeout(timeOutRef.current);
			timeOutRef.current = null;
			getCompanySummary(currentSymbol, dispatch).then(res => {
				const marketHolidays = state.companyInfo?.market_hours.stockMarketHolidays;
				setCompanySummary(res);
				if (!isMarketHolidayToday(marketHolidays)) {
					companySummaryPolling();
				}
				if (extendedHoursClosedDuration() || isMarketHolidayToday(marketHolidays)) {
					getPrePostMarketTrade(currentSymbol, dispatch);
				}
			})
		}
	}, [state.companyInfo?.market_hours]);

	useEffect(() => {
		if (state.companyInfo?.company_summary && state.companyInfo?.company_summary.length > 0) {
			let currentMarket = {};
			currentMarket.price = state.companyInfo?.company_summary[0]?.price.toFixed(2);
			currentMarket.changes = state.companyInfo?.company_summary[0]?.change.toFixed(2);
			currentMarket.changePercentage = state.companyInfo?.company_summary[0]?.changesPercentage.toFixed(2);
			setIntradayTrade(currentMarket);

			setMarketOpen(state.companyInfo?.market_hours?.isTheStockMarketOpen ?? marketHours.isTheStockMarketOpen);
			setCompanySummary(state.companyInfo?.company_summary);
		} else
			setIntradayTrade(null);
	},[state.companyInfo?.company_summary]);

	useEffect(() => {
		if (state.companyInfo?.pre_post_market_trade
			&& state.companyInfo?.pre_post_market_trade.price) {
			let prePostMarket = {};
			const prePostCurrentPrice = state.companyInfo?.pre_post_market_trade.price;
			const marketClosePrice = state.companyInfo?.company_summary ? state.companyInfo?.company_summary[0].price : companySummary[0]?.price;
			prePostMarket.price = prePostCurrentPrice.toFixed(2);
			if (marketClosePrice) {
				const changes = prePostCurrentPrice - marketClosePrice;
				prePostMarket.changes = changes.toFixed(2);
				prePostMarket.changePercentage = ((changes / marketClosePrice) * 100).toFixed(2);
			}
			setPrePostTrade(prePostMarket);

			let prePostMarketClosed = false;
			if (extendedHoursClosedDuration())
				prePostMarketClosed = true;
			if (isMarketHolidayToday(state.companyInfo?.market_hours?.stockMarketHolidays || marketHours.stockMarketHolidays))
				prePostMarketClosed = true;
			setAfterMarketClosed(prePostMarketClosed);
		} else
			setPrePostTrade(null);
	},[state.companyInfo?.pre_post_market_trade]);

	const getTypographyVariant = () => {
		if (screenSize < 600) {
			return "h6";
		} else if (screenSize < 900) {
			return "h5";
		} else {
			return "h4";
		}
	};

	useEffect(() => {
		getAllSymbols(dispatch);
		const handleResize = () => {
			setScreenSize(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

    return (
		<>
            <div className={classes.subHeader}>
                <Autosuggest
                    ref={autoSuggestInputFieldRef}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    onSuggestionSelected={onSuggestionSelected}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                    focusInputOnSuggestionClick={false}
                    shouldRenderSuggestions={shouldRenderSuggestions}
                />
                <FormGroup className={classes.switchRoot}>
                    <FormControlLabel control={<Switch checked={quarterlyReport} onChange={toggleSwitchReportType} />}
                        label={quarterlyReport? "Quarterly": "Annually"}/>
                </FormGroup>
                <div class="company-title">
                    {
                        state.symbol && state.symbol.data && state.symbol.data.symbol && (
							<Typography variant={getTypographyVariant()}>{state.symbol.data.name} ({state.symbol.data.symbol})</Typography>
                        )
                    }
				</div>
				<div class="company-sub-info">
					{intradayTrade && (
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<div className={classes.tableContainer}>
								<div className={classes.tableRow}>
									<div className={classes.tableColumn}>
										<Typography variant="h4" color="primary" classes={{h4: classes.customTypographySubtitle2}}>{intradayTrade.price}</Typography>
										{ !marketOpen &&
											<Typography gutterBottom variant="caption" classes={{caption: classes.customTypographyCaption}}>
											At Close: 04:00 PM EST
											</Typography>
											}
									</div>
									<div className={classes.tableColumn}>
										<div className={classes.priceChanges}>
											<Typography variant="button" color="textPrimary"
														classes={{colorTextPrimary: intradayTrade.changes > 0 ? classes.positiveChanges: classes.negativeChanges}}>
												{ intradayTrade.changes >= 0 ? '+' + intradayTrade.changes : intradayTrade.changes }
												({ intradayTrade.changePercentage >= 0 ? '+' + intradayTrade.changePercentage: intradayTrade.changePercentage }%)
											</Typography>
										</div>
									</div>
								</div>
							</div>
						</Grid>
						{ prePostTrade && prePostTrade.price && (
						<Grid item xs={6}>
							<div className={classes.tableContainer}>
								<div className={classes.tableRow}>
									<div className={classes.tableColumn}>
										<Typography variant="h4" color="primary" classes={{h4: classes.customTypographySubtitle2}}>{prePostTrade.price}</Typography>
										{afterMarketClosed &&
											<Typography gutterBottom variant="caption" classes={{caption: classes.customTypographyCaption}}>
											After Hours Close: 08:00 PM EST
											</Typography>
											}
									</div>
									<div className={classes.tableColumn}>
										<div className={classes.priceChanges}>
											<Typography variant="button" color="textPrimary"
														classes={{colorTextPrimary: prePostTrade.changes >= 0 ? classes.positiveChanges: classes.negativeChanges}}>
												{prePostTrade.changes >= 0 ? '+' + prePostTrade.changes : prePostTrade.changes}
												({prePostTrade.changes >= 0 ? '+' + prePostTrade.changePercentage: prePostTrade.changePercentage }%)
											</Typography>
										</div>
									</div>
								</div>
							</div>
						</Grid>
							)}
					</Grid>
						)}
				</div>
                <SimpleSpinner loading={loading} />
                    {
                        state && state.incomeStatement && state.incomeStatement.data && (
                            <div>
                                <div class="nav-container">
                                    <div class="sub-nav-link">
										<Tabs
											classes={{scrollButtonsDesktop: classes.muiTabs_scrollButtonsDesktop}}
											value={tabValue}
											onChange={handleChange}
											indicatorColor="primary"
											textColor="primary"
											variant="scrollable"
										>
											<Tab classes={{root: classes.muiTabRoot}} label="Market" {...a11yProps(0)} />
											<Tab classes={{root: classes.muiTabRoot}} label="Income Statement" {...a11yProps(1)} />
											<Tab classes={{root: classes.muiTabRoot}} label="Balance Sheet" {...a11yProps(2)} />
											<Tab classes={{root: classes.muiTabRoot}} label="Cash Flow" {...a11yProps(3)} />
											<Tab classes={{root: classes.muiTabRoot}} label="Quarterly/Annual Report(10-Q/K)" {...a11yProps(4)} />
											<Tab classes={{root: classes.muiTabRoot}} label="Other" {...a11yProps(5)} />
										</Tabs>
										<div style={{display: "none"}}>
										<NavLink to="income" className={({ isActive }) => isActive ? "subNavAlign activeNav" : "subNavAlign"}>Income Statement</NavLink>
                                        <NavLink to="balancesheet" className={({ isActive }) => isActive ? "subNavAlign activeNav" : "subNavAlign"}>Balance Sheet</NavLink>
                                        <NavLink to="cashflow" className={({ isActive }) => isActive ? "subNavAlign activeNav" : "subNavAlign"}>Cash Flow</NavLink>
                                        <NavLink to="quarterlyannualreport" className={({ isActive }) => isActive ? "subNavAlign activeNav" : "subNavAlign"}>Quarterly/Annual Report(10-Q/K)</NavLink>
										<NavLink to="companygrowth" className={({ isActive }) => isActive ? "subNavAlign activeNav" : "subNavAlign"}>Historical Growth</NavLink>
                                        <NavLink to="other" className={({ isActive }) => isActive ? "subNavAlign activeNav" : "subNavAlign"}>Other</NavLink>
										</div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
            </div>
			<main className={classes.bodyContainer}>
				<div className={`${state.theme.className} ${state.rtl.direction}-support`} dir={state.rtl.direction}>
					<TabPanel value={tabValue} index={0}>
						<Market/>
					</TabPanel>
					<TabPanel value={tabValue} index={1}>
						<IncomeStatement/>
					</TabPanel>
					<TabPanel value={tabValue} index={2}>
						<BalanceSheet/>
					</TabPanel>
					<TabPanel value={tabValue} index={3}>
						<CashFlow/>
					</TabPanel>
					<TabPanel value={tabValue} index={4}>
						<QuarterlyAndAnnualReport/>
					</TabPanel>
					<TabPanel value={tabValue} index={5}>
						<Other/>
					</TabPanel>
				</div>
			</main>
		</>
    )

}

export default SearchBar;